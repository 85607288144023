import { useState } from "react";
import FormFooter from "./FormFooter";
import "./index.scss";
import RTE from "../../RTE";

function Step4({ value, onChange }) {
  return (
    <>
      <RTE
        name="jobDescription"
        value={value}
        onChange={onChange}
      />
    </>
  );
}

export default Step4;
