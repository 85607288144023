import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import recordContext from "../context/recordContext";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";
import { useDispatch } from "react-redux";
import { setQuestions, setStartTime, setTestTime } from "../redux/slices/questions/questionSlice";

const GrantPermissions = () => {
    const navigate = useNavigate();
    const context = useContext(recordContext);
    const [stepper, setStepper] = useState(0);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const {
        captureScreen,
        captureUserMedia,
        togglePiP,
        startRecording,
    } = context;

    const getUserMediaStatus = async () => {
        return await captureUserMedia();
    }

    const shareCamera = async () => {
        const status2 = await getUserMediaStatus();
        if (status2 === 'SUCCESS') {
            setStepper(2);
        } else {
            toast.error("Kindly enable camera and microphone settings");
        }
    }

    const grantPermissions = async () => {
        const status = await captureScreen();
        if (status === 'SUCCESS') {
            const status2 = await getUserMediaStatus();
            if (status2 === 'SUCCESS') {
                setStepper(2);
            } else {
                setStepper(1);
            }
        }
    }

    const handleStartRecording = async () => {
        setIsLoading(true);
        try {
            const jobAppId = searchParams.get("jobAppId");
            const token = localStorage.getItem('auth')
            const response = await axiosInstance.post(`/api/v3/job-applications/${jobAppId}/start-test?token=${token}`);
            console.log({response})
            dispatch(setTestTime(response.data.data.endsAt));
            dispatch(setStartTime(response.data.data.startedAt));
            if (response.data?.data?.test?.questions) {
                dispatch(setQuestions(response.data.data.test.questions));
                localStorage.setItem("jobApplicationId", response.data.data.jobApplicationId);
                localStorage.setItem("candidateTestId", response.data.data.candidateTestId);
                localStorage.setItem("jobId", response.data.data.jobId);
            }
            
            // First start recording, then toggle PiP
            const startRec = await startRecording();
            if(startRec === 'SUCCESS') {
                const status = await togglePiP();
                console.log('Pip Status', status)
                if (status === 'SUCCESS') {
                    navigate(`/main-test?candidateTestId=${response.data?.data?.candidateTestId}`);
                } else {
                    setIsLoading(false);
                    toast.error("Failed to enable Picture-in-Picture mode");
                }
            } else {
                setIsLoading(false);
                toast.error("Failed to start recording");
            }
        } catch (error) {
            toast.error("Failed to start the test. Please contact your recruiter");
            setIsLoading(false);
        }
    }

    return (
        <div className="fixed-bottom text-center permission-footer">
            <div className="d-flex align-items-center justify-content-around">
                <div>
                    <p className="m-0">Grant permissions</p>
                </div>
                <div>
                    <button className="btn btn-sub mr-2" onClick={() => { navigate('/candidates-view'); }}>Go back</button>

                    {stepper == 0 && <button type="button" className="btn btn-main" onClick={grantPermissions}>Grant permission</button>}

                    {stepper == 1 && <button
                        type="button"
                        className="btn btn-main"
                        onClick={shareCamera}
                    >
                        Share camera
                    </button>}

                    {stepper == 2 && <button
                        type="button"
                        className="btn btn-main"
                        onClick={handleStartRecording}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <>
                                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                Starting Test...
                            </>
                        ) : (
                            'Take Test'
                        )}
                    </button>}
                </div>
            </div>
        </div>
    )
}

export default GrantPermissions;