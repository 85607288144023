import React, { useState } from "react";
import { Outlet, useNavigate, useLocation, Link } from "react-router-dom";
import AppNav from "../AppNav";
// Import your Header/Navbar component here
// import Header from '../Header';
import AssessmentPadLogo from "../AssessmentPadLogo";
import Logo from "../../assets/icons/logo.svg";

const PublicLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div
      style={{
        background: "linear-gradient(to bottom, #0d0d0d, #141616)",
        color: "#d6d6d6",
      }}
    >
      <header>
        <Link
          to="/"
          // onClick={(e) => {
          //   e.preventDefault();
          //   window.scrollTo({ top: 0, behavior: "smooth" });
          // }}
        >
          <AssessmentPadLogo />
        </Link>
        {location.pathname !== "/contact-us" && (
          <>
            <button
              className="mobile-menu-btn"
              onClick={toggleMobileMenu}
              aria-label="Toggle mobile menu"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <nav className={isMobileMenuOpen ? "mobile-menu-open" : ""}>
              <ul>
                <li>
                  <a href="#features">Features</a>
                </li>
                <li>
                  <a href="#how-it-works">How it works</a>
                </li>
                <li>
                  <a href="#faqs">FAQs</a>
                </li>
              </ul>
            </nav>
          </>
        )}
        <button
          onClick={() => {
            navigate("/login");
          }}
          className="signup-btn"
        >
          Explore
        </button>
      </header>

      <Outlet />

      <footer className="footer">
        <img src={Logo} alt="logo" />
        <div className="footer-links">
          <Link to="/contact-us">Contact</Link>
          <Link to="/privacy">Privacy</Link>
          <Link to="/terms">Terms & Conditions</Link>
        </div>
        <div className="footer-made-with">
          Made with{" "}
          <span className="heart" style={{ color: "red", fontSize: "20px" }}>
            ♥
          </span>{" "}
          in India
        </div>
      </footer>
    </div>
  );
};

export default PublicLayout;
