import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

import Loader from "../common/Loader";
import DropdownFilter from "../common/DropdownFilter";
import solvedQuestionRight from "../../assets/images/solvedQuestionRight.svg";
import clockIcon from "../../assets/images/clockIconVieAllQuestionPage.svg";
import rightPaginationArrow from "../../assets/images/rightPaginationArrow.svg";
import leftPaginationArrow from "../../assets/images/leftPaginationArrow.svg";
import { useEffect, useMemo, useState } from "react";

const quizzesPerPage = 10;

const QuesListingPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { questions, attemptedQuestions, testTime, startTime } = useSelector((state) => {
    return state.questions;
  });
  const location = useLocation();

  const candidateTestId= searchParams.get("candidateTestId");

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [questionStatus, setQuestionStatus] = useState("All");
  const [buttonRange, setButtonRange] = useState({ start: 1, end: 10 });
  const [error, setError] = useState(null);
  const [questionType, setQuestionType] = useState("ALL");

  const calculateRemainingTime = (startTimeStr, endTimeStr) => {
    const endTime = new Date(endTimeStr);
    const startTimeDate = new Date(startTimeStr);
    const now = new Date();
    
    // If current time is before start time, show total duration
    if (now < startTimeDate) {
      return Math.floor((endTime - startTimeDate) / 1000); // Return seconds
    }
    
    // If current time is after start time, show remaining time
    const diffSeconds = Math.floor((endTime - now) / 1000);
    return Math.max(0, diffSeconds);
  };

  const [remainingTime, setRemainingTime] = useState(() => 
    calculateRemainingTime(startTime, testTime)
  );



  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const questionStatusValues = ["All", "Solved", "Unsolved"];
  const questionTypeValues = ["All", "Coding", "MCQ"];

  const filteredQuestionsList = useMemo(() => {
    if (!questions.length) return [];
    
    let filtered = [...questions];

    if (questionType !== "ALL") {
      filtered = filtered.filter(
        (elem) => elem.questionType === questionType
      );
    }

    if (questionStatus === "Solved") {
      filtered = filtered.filter((elem) =>
        attemptedQuestions?.hasOwnProperty(elem.id)
      );
    } else if (questionStatus === "Unsolved") {
      filtered = filtered.filter(
        (elem) => !attemptedQuestions?.hasOwnProperty(elem.id)
      );
    }

    return filtered;
  }, [questions, questionType, questionStatus, attemptedQuestions]);

  const currentPageQuestions = useMemo(() => {
    const startIndex = (currentPage - 1) * quizzesPerPage;
    return filteredQuestionsList.slice(startIndex, startIndex + quizzesPerPage);
  }, [filteredQuestionsList, currentPage]);

  const endTest = () => {
    const jobApplicationId = localStorage.getItem("jobApplicationId")
    confirmAlert({
      title: "Submit Test",
      message: "Are you sure to end test now? This cant be undone.",
      buttons: [
        {
          label: "End Test",
          onClick: async () => {
            await axiosInstance.post(
              `/api/v3/job-applications/${jobApplicationId}/end-test`
            );
            navigate(`/`);
          },
        },
        { label: "Cancel" },
      ],
    });
  };

  const filterQuestionUpdate = (value) => {
    setQuestionType(value.toUpperCase());
    setCurrentPage(1);
    setButtonRange({ start: 1, end: 10 });
  };

  const filterQuestionStatus = (value) => {
    setQuestionStatus(value);
    setCurrentPage(1);
    setButtonRange({ start: 1, end: 10 });
  };

  const navToQuestion = (queId) => {
    navigate(`/temp-code?candidateTestId=${candidateTestId}`, {
      state: { queId: queId.toString() },
    });
  };

  const totalPages = useMemo(
    () => Math.ceil(filteredQuestionsList.length / quizzesPerPage),
    [filteredQuestionsList]
  );

  const handleNextRange = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
      
      if (currentPage >= buttonRange.end) {
        setButtonRange(prev => ({
          start: prev.start + 1,
          end: Math.min(prev.end + 1, totalPages)
        }));
      }
    }
  };

  const handlePrevRange = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
      
      if (currentPage <= buttonRange.start) {
        setButtonRange(prev => ({
          start: Math.max(prev.start - 1, 1),
          end: prev.end - 1
        }));
      }
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = buttonRange.start; i <= buttonRange.end; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? "active" : ""}
          disabled={i > Math.ceil(filteredQuestionsList.length / 10)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="viewAllQuestionPage">
          <div className="viewAllQestionPageHeader">
            <div className="timerClock">
              <img src={clockIcon} className="clockIcon" alt="Clock Icon" />
              {/* <span className="clockText">{formatTime(remainingTime)}</span> */}
              <span className="clockText">12:23</span>
            </div>
          </div>
          <div className="test-listing-page-main p-5 flex-grow-1 d-flex flex-column">
            <div className="d-flex px-3">
              <div className="w-75">Questions</div>
              <div className="w-25 d-flex flex-row justify-content-between">
                <div className="questionTypeSelect">
                  <DropdownFilter
                    name="questionType"
                    styleClass="viewAllQnPageDropdown"
                    dropdownName="Type"
                    dropdownOptions={questionTypeValues}
                    dropdownHandleClick={filterQuestionUpdate}
                  />
                </div>
                <div className="questionStatusSelect">
                  <DropdownFilter
                    name="questionStatus"
                    styleClass="viewAllQnPageDropdown"
                    dropdownName="Status"
                    dropdownOptions={questionStatusValues}
                    dropdownHandleClick={filterQuestionStatus}
                  />
                </div>
              </div>
            </div>
            <div className="mt-2 test-segregation-box flex-grow-1">
              {currentPageQuestions.length > 0 ? (
                currentPageQuestions.map((que, i) => (
                  <div className="code-test-card d-flex" key={i}>
                    <div className="w-75">
                      {(currentPage - 1) * 10 + i + 1}. {que.title}
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-25">
                      <div className="test-page-qn-type">
                        {que.questionType === "MCQ" ? "MCQ" : "Coding"}
                      </div>
                      <button
                        type="button"
                        onClick={() => navToQuestion(que.id)}
                        className="test-page-action-btn col-1 d-flex justify-content-center"
                        style={{
                          backgroundColor: attemptedQuestions[que.id]
                            ? "#404342"
                            : "#B4FF45",
                          color: attemptedQuestions[que.id]
                            ? "#868C8A"
                            : "#131514",
                        }}
                        disabled={attemptedQuestions[que.id]}
                      >
                        {attemptedQuestions[que.id] && (
                          <img
                            src={solvedQuestionRight}
                            alt="Solved Icon"
                            className="solvedTickIcon"
                          />
                        )}
                        
                        {attemptedQuestions[que.id] ? "Solved" : "Solve"}
                        
                      </button>
                    </div>
                  </div>
                ))
              ) : questions.length > 0 ? (
                <h1 className="text-center text-warning">
                  No questions match the selected filters
                </h1>
              ) : (
                <h1 className="text-center">No questions available</h1>
              )}
            </div>
            <div className="viewAllQuestionPagePagination">
              <div className="paginationNoBtns">
                {renderPaginationButtons()}
              </div>
              <div className="paginationNextPrevBtn">
                <button
                  className="leftPaginationArrow"
                  onClick={handlePrevRange}
                  disabled={currentPage === 1}
                >
                  <img
                    className="rightPaginationArrow"
                    src={leftPaginationArrow}
                    alt="Next Question"
                  />
                </button>
                <button
                  className="rightPaginationArrow"
                  onClick={handleNextRange}
                  disabled={currentPage === totalPages}
                >
                  <img
                    className="rightPaginationArrow"
                    src={rightPaginationArrow}
                    alt="Next Question"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="footerBar">
            <button onClick={endTest}>Submit Test</button>
          </div>
        </div>
      )}
    </>
  );
};

export default QuesListingPage;
