import React from 'react';
import styles from './FeatureCard.module.css';


export function FeatureCard({ title, description, icons, rating }) {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <h2 className={styles.cardTitle}>{title}</h2>
        <div className={styles.iconContainer}>
          {icons.map((icon, index) => (
            <span key={index} className={styles.icon}>{icon}</span>
          ))}
          {rating && (
          <div className={styles.rating}>
            {[...Array(5)].map((_, i) => (
              <span key={i} className={styles.star}>★</span>
            ))}
          </div>
        )}
        </div>
      </div>
      <div className={styles.cardContent}>
        <p className={styles.description}>{description}</p>
        
      </div>
    </div>
  )
}

