import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs } from "../../../redux/slices/jobsSlice";
import "./recruiterCandidate.scss";
import NoList from "../../../assets/images/no-list.svg";
import "./recruiterCandidate.scss";
import rightPaginationArrow from "../../../assets/images/rightPaginationArrow.svg";
import leftPaginationArrow from "../../../assets/images/leftPaginationArrow.svg";
import DropdownFilter from "../../common/DropdownFilter";
import axios from "axios";

const candidatesPerPage = 5;

export default function RecruiterCandidate() {
  const dispatch = useDispatch();
  const { jobs, loading } = useSelector((state) => state.jobs);
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedTest, setSelectedTest] = useState("");
  const [tests, setTests] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [loadingTests, setLoadingTests] = useState(false);
  const [loadingCandidates, setLoadingCandidates] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [buttonRange, setButtonRange] = useState({ start: 1, end: 10 });
  const totalPages = useMemo(
    () => Math.ceil(candidates?.length / candidatesPerPage),
    [candidates]
  );
  const showList = selectedJob && selectedTest;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    dispatch(fetchJobs({ token: user._token, recruiterId: user.recruiterId }));
  }, [dispatch]);

  const jobValues = useMemo(() => {
    return jobs.map((job) => job.title);
  }, [jobs]);

  const fetchTests = async (jobId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      setLoadingTests(true);
      const response = await axios.get(
        `https://api.assessmentpad.com/api/v3/jobs/${jobId}/tests?token=${user._token}`
      );
      setTests(response.data.data);
    } catch (error) {
      console.error("Error fetching tests:", error);
    } finally {
      setLoadingTests(false);
    }
  };

  const handleSelectJob = (value) => {
    setSelectedJob(value);
    const selectedJobData = jobs.find((job) => job.title === value);

    if (selectedJobData?.id) {
      fetchTests(selectedJobData.id);
    }
    setSelectedTest("");
  };

  const handleSelectTest = (value) => {
    setSelectedTest(value);
    const selectedJobData = jobs.find((job) => job.title === selectedJob);
    const testId = tests.find((t) => t.title === value).testId;
    if (selectedJobData?.id && value) {
      fetchCandidates(selectedJobData.id, testId);
    }
  };

  const handlePrevRange = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (buttonRange.start >= currentPage) {
      const prevStart = Math.min(buttonRange.start - 1, totalPages - 10);
      const prevEnd = Math.min(buttonRange.end - 1, totalPages);
      if (currentPage <= buttonRange.start) {
        setButtonRange({ start: prevStart, end: prevEnd });
      }
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = buttonRange.start; i <= buttonRange.end; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? "active" : ""}
          // disabled={i > Math.ceil(filteredQuestions.length / 10)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };
  console.log({ showList });

  const handleNextRange = () => {
    if (totalPages > currentPage) {
      setCurrentPage(currentPage + 1);
    }
    if (buttonRange.end <= currentPage) {
      const nextStart = Math.min(buttonRange.start + 1, totalPages - 10 + 1);
      const nextEnd = Math.min(buttonRange.end + 1, totalPages);
      if (currentPage < totalPages) {
        setButtonRange({ start: nextStart, end: nextEnd });
      }
    }
  };
  console.log({ tests });

  // Transform tests data for dropdown
  const testValues = useMemo(() => {
    return tests.map((test) => ({
      value: test.id,
      label: test.title || "-",
    }));
  }, [tests]);

  const fetchCandidates = async (jobId, testId) => {
    try {
      setLoadingCandidates(true);
      const token = JSON.parse(localStorage.getItem("auth"));
      const response = await axios.get(`/api/v3/jobs/${jobId}/candidates`, {
        params: {
          token,
          testId,
        },
      });
      setCandidates(response.data.data.data || []);
    } catch (error) {
      console.error("Error fetching candidates:", error);
      setCandidates([]);
    } finally {
      setLoadingCandidates(false);
    }
  };
  
  return (
    <div className="candidates-main-container">
      <div className="flex justify-between mb-4 filter-container">
        <div className="filter-section">
          <div className="w-[48%]">
            <div className="relative">
              <DropdownFilter
                name="questionType"
                styleClass="viewAllQnPageDropdown"
                dropdownName={selectedJob || "Select Job"}
                dropdownOptions={jobValues}
                dropdownHandleClick={handleSelectJob}
                isLoading={loading}
              />
            </div>
          </div>
          <div className="w-[48%] tw-ml-10">
            {selectedJob && (
              <div className="relative">
                <DropdownFilter
                  name="questionType"
                  styleClass="viewAllQnPageDropdown"
                  dropdownName={
                    selectedTest && selectedTest != ""
                      ? selectedTest
                      : "Select Test"
                  }
                  dropdownOptions={testValues.map((v) => v.label)}
                  dropdownHandleClick={handleSelectTest}
                  isLoading={loadingTests}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {!showList ? (
        <div className="no-list-container">
          <div className="text-center">
            {/* <img src={NoList} /> */}
            <h3 className="no-list-text">No list to show</h3>
            <p className="text-description">
              Select from the dropdown above to
              <br />
              view candidate list
            </p>
          </div>
        </div>
      ) : loadingCandidates ? (
        <div className="loading-container">
          <p>Loading candidates...</p>
        </div>
      ) : candidates.length === 0 ? (
        <div className="no-list-container">
          <div className="text-center">
            <img src={NoList} alt="No candidates" />
            <h3 className="no-list-text">No candidates found</h3>
            <p className="text-description">
              There are no candidates available for this job and test
              combination
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="candidate-list">
            {candidates.map((candidate) => (
              <div key={candidate.id} className="candidate-card">
                <div className="candidate-info">
                  <div
                    className={`avatar ${candidate.avatarColor || "blue"}`}
                    style={{ height: 60, width: 60 }}
                  >
                    {candidate.candidate.name
                      ?.split(" ")
                      .map((n) => n[0])
                      .join("")}
                  </div>
                  <div className="details">
                    <h3>{candidate.candidate.name}</h3>
                    <div className="contact-info">
                      <a href={`mailto:${candidate.candidate.email}`}>
                        {candidate.candidate.email}
                      </a>
                      <span style={{color:"#868C8A"}}>{candidate.candidate.mobileNumber}</span>
                    </div>
                    <div className="tags">
                      {candidate.tags?.map((tag) => (
                        <span key={tag} className="tag">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="actions">
                  <button
                    type="button"
                    className="view-video"
                    style={{
                      backgroundColor: "#B4FF45",
                      color: "#131514",
                      fontWeight: 600,
                    }}
                    onClick={() => window.open(candidate.videoUrl, "_blank")}
                  >
                    View Video
                  </button>
                  <button
                    className="view-resume"
                    onClick={() => window.open(candidate.metadata.preSignedS3KeyForResume, "_blank")}
                  >
                    View Resume
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="pagination">
            <div className="paginationNoBtns">{renderPaginationButtons()}</div>
            <div className="paginationNextPrevBtn">
              <button
                className="leftPaginationArrow"
                onClick={handlePrevRange}
                disabled={currentPage === 1}
              >
                <img
                  className="rightPaginationArrow"
                  src={leftPaginationArrow}
                  alt="Next Question"
                />
              </button>
              <button
                className="rightPaginationArrow"
                onClick={handleNextRange}
                disabled={currentPage === totalPages}
              >
                <img
                  className="rightPaginationArrow"
                  src={rightPaginationArrow}
                  alt="Next Question"
                />
              </button>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
}
