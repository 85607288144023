import styles from './FeatureTag.module.css';

export function FeatureTag({ text }) {
  return (
    <span className={styles.tag}>
      {text}
    </span>
  )
}

