import { useEffect, useState } from "react";
import Logo from "../assets/icons/logo.svg";
import AssessmentPadLogo from "./AssessmentPadLogo";
import "./config.css";
import Loader from "./common/Loader";

export default function Privacy() {
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await fetch(
          `https://api.assessmentpad.com/api/app-configs?configName=PRIVACY`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch privacy policy");
        }

        const data = await response.json();
        setPrivacyPolicy(data.data[0].data.description);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTerms();
  }, []);
  console.log({ privacyPolicy });
  return (
    <div className="landing-page">
      <main className="terms-content">
        {loading && (
          <div className="loading">
            <Loader />
          </div>
        )}
        {error && <div className="error">Error: {error}</div>}
        {privacyPolicy && (
          <div
            className="privacy-content"
            dangerouslySetInnerHTML={{ __html: privacyPolicy }}
          />
        )}
      </main>
    </div>
  );
}

// export default function Privacy() {
//   return (
//     <>
//       <div className="main-body" style={{padding: '30px', textAlign: 'center', display: 'grid',  color: 'inherit'}}>
//           <h1 Style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: center;">
//             Privacy Policy
//           </h1>
//           <hr className="head-divider" />
//           <div className="text-content-body">
//             <h2 Style="padding-top: 18pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//               PRIVACY NOTICE FOR USERS WHO APPLY OR SIGN UP FOR A JOBIFY Inc.
//               ACCOUNT ONLY
//             </h2>
//             <p
//               class="s1"
//               Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;text-align: left;"
//             >
//               Effective Date: October 9, 2023
//             </p>
//             <p Style="padding-top: 9pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//               This Privacy Notice describes how the Jobify Inc platform (referred
//               to herein as “Jobify”) uses and discloses the information we collect
//               from you as a customer of the Jobify platform (collectively,
//               “Services”). Jobify is a product and service owned by Block, Inc.,
//               and references to “us,” “we” or “our” in this Privacy Notice refer
//               to Block, Inc. and its affiliates.
//             </p>
//             <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//               This Privacy Notice applies to the information collected in
//               connection with your use of Jobify, but does not apply to
//               information of yours that we may collect through our other services
//               or products.
//             </p>
//             <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: justify;">
//               This Privacy Notice applies to information collected in connection
//               with your access to and use of our Services. Please read this
//               Privacy Notice carefully. By continuing to interact with our
//               Services, you are consenting to the practices described in this
//               Privacy Notice.
//             </p>
//             <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//               Our Privacy Notice explaination follows:
//             </p>
//             <p Style="text-indent: 0pt;text-align: left;">
//               <br />
//             </p>
//             <ol id="l1">
//               <li data-list-text="1.">
//                 <h3 Style="padding-top: 8pt;padding-left: 17pt;text-indent: -11pt;text-align: left;">
//                   INFORMATION WE COLLECT ABOUT YOU
//                 </h3>
//                 <h4 Style="padding-top: 7pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                   Information You Provide
//                 </h4>
//                 <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                   We collect information you provide when you:
//                 </p>
//                 <ul id="l2">
//                   <li data-list-text="•">
//                     <p Style="padding-top: 8pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Make a request to receive information about Jobify;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Apply or sign up for a Jobify account or other Services;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       Go through our account verification process, authenticate
//                       into your account, communicate with us, answer our surveys,
//                       upload content, or otherwise interact with the Services.
//                     </p>
//                     <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       We call this information <b>Jobify Account Data</b>.
//                     </p>
//                     <p Style="padding-top: 9pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       Specifically, we collect (and/or have collected during at
//                       least the 12-month period preceding the effective date of
//                       this Privacy Notice) the following categories of
//                       information:
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;"
//                     >
//                       Identification Information<span class="p">, such as:</span>
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Name, email address, postal address, signature, and phone
//                       number.
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Financial Information
//                       <span class="p">
//                         , such as your credit card details, PayPal email address,
//                         and other information necessary to process the
//                         transaction. We will retain the last four digits of your
//                         credit card number, credit card type, and associated name
//                         and address, which you may identify as a card used in the
//                         future.
//                       </span>
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Other Information You Provide
//                       <span class="p">
//                         , such as information that you voluntarily provide to us,
//                         including your survey responses; participation in
//                         contests, promotions, or other prospective seller
//                         marketing forms or devices; suggestions for improvements;
//                         referrals; or any other actions you perform on the
//                         Services.
//                       </span>
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       Information We Collect From Your Use of the Services
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Commercial information
//                       <span class="p">
//                         , such as information about the products and services you
//                         sell (e.g., inventory, pricing and other data) and
//                         information about your payment transactions (e.g., when
//                         and where the transactions occur, a description of the
//                         transactions, the payment or transfer amounts, billing and
//                         shipping information, and payment methods used to complete
//                         the transactions);
//                       </span>
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;text-align: left;"
//                     >
//                       Electronic information
//                       <span class="p">
//                         , such as web browser and device characteristics;
//                       </span>
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Geolocation data
//                       <span class="p">
//                         , which includes the location of your device. This may
//                         include precise geolocation information. For more
//                         information and to learn how to disable collection of
//                         precise geolocation information from your mobile
//                       </span>
//                     </p>
//                     <p Style="padding-top: 3pt;padding-left: 22pt;text-indent: 0pt;text-align: left;">
//                       device, please see below;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Internet or other electronic network activity information
//                       <span class="p">
//                         , which includes information about how you use and
//                         interact with our Services, including your access time,
//                         “log-in” and “log-out” information, browser type and
//                         language, the domain name of your internet service
//                         provider, other attributes about your browser, any
//                         specific page you visit on our platform, content you view,
//                         features you use, the date and time of your use of the
//                         Services, your search terms, and the website you visited
//                         before you visited or used the Services;
//                       </span>
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Online identifiers{" "}
//                       <span class="p">
//                         (e.g., information you use to log in to your account),
//                         Internet Protocol (“IP”) address, and unique personal
//                         identifiers (including device identifier; cookies,
//                         beacons, pixel tags, mobile ad identifiers and similar
//                         technology; customer number; unique alias, and other
//                         identifiers); and
//                       </span>
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Inferences{" "}
//                       <span class="p">
//                         drawn from any of the information above to create a
//                         profile about you that may reflect, for example, your
//                         preferences, characteristics, and behavior, including for
//                         account security purposes or to enhance our Services to
//                         you.
//                       </span>
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                   </li>
//                 </ul>
//               </li>
//               <li data-list-text="2.">
//                 <h3 Style="padding-top: 5pt;padding-left: 20pt;text-indent: -13pt;text-align: left;">
//                   INFORMATION WE MAY COLLECT ABOUT YOUR CUSTOMERS
//                 </h3>
//                 <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                   We also obtain information about your customers on your behalf
//                   when they transact with you. We call this information Your
//                   Customers’ Data. We collect Your Customers’ Data when they
//                   transact with you through your use of Jobify. Your Customers’
//                   Data may include:
//                 </p>
//                 <ul id="l3">
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Device Information.{" "}
//                       <span class="p">
//                         Information about Your Customer’s device, including
//                         hardware model, operating system and version, device name,
//                         unique device identifier, mobile network information, and
//                         information about the device’s interaction with our
//                         Services.
//                       </span>
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Identification Information.{" "}
//                       <span class="p">
//                         Your Customers’ name; email address; mailing address;
//                         phone number; or other historical, and contact
//                         information.
//                       </span>
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Location Information.{" "}
//                       <span class="p">
//                         The location of Your Customer’s device if they pay with
//                         Apple or Android Pay. This may include precise geolocation
//                         information.
//                       </span>
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Transaction Information.{" "}
//                       <span class="p">
//                         When Your Customers use Jobify to make or record payments
//                         to you, we collect information about when and where the
//                         transactions occur, the names of the transacting parties,
//                         a description of the transactions which may include
//                         item-level data, the payment or transfer amounts, billing
//                         and shipping information, and the devices and payment
//                         methods used to complete the transactions.
//                       </span>
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Use Information.{" "}
//                       <span class="p">
//                         Information about how Your Customers transact with you
//                         using our Services, including access time, “log-in” and
//                         “log-out” information, browser type and language, country
//                         and language setting on your device, IP address, the
//                         domain name of Your Customer’s internet service provider,
//                         other attributes about Your Customer’s browser, mobile
//                         device and operating system, features Your Customer uses,
//                         and the date and time of use of the Services.
//                       </span>
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s1"
//                       Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       Other Information You or Your Customers Provide.{" "}
//                       <span class="p">
//                         Information that Your Customers voluntarily provide you or
//                         that you input into Jobify’s systems about your Customers,
//                         including survey responses; participation in contests,
//                         promotions, or other prospective seller marketing forms or
//                         devices; suggestions for improvements; or any other
//                         actions performed when they transact with you using
//                         Jobify.
//                       </span>
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                   </li>
//                 </ul>
//               </li>
//               <li data-list-text="3.">
//                 <h3 Style="padding-top: 5pt;padding-left: 20pt;text-indent: -13pt;text-align: left;">
//                   SOURCES OF INFORMATION WE COLLECT ABOUT YOU
//                 </h3>
//                 <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                   We collect (and/or have collected during at least the 12-month
//                   period preceding the effective date of this Privacy Notice)
//                   information about you from the following categories of sources:
//                 </p>
//                 <ul id="l4">
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       You directly, when you submit information to us or allow us
//                       to access information about you;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Your devices and applications when you interact with our
//                       website or use our Services;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Our group companies and affiliates;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Other sources, including:
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Social media networks;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 3pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Online advertising companies;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Mailing list providers; and
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Publicly available sources (such as public records of
//                       criminal convictions and arrest records).
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                   </li>
//                 </ul>
//               </li>
//               <li data-list-text="4.">
//                 <h3 Style="padding-top: 5pt;padding-left: 20pt;text-indent: -13pt;text-align: left;">
//                   HOW WE USE YOUR INFORMATION
//                 </h3>
//                 <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                   We may collect, use and share (or have collected, used or shared
//                   during at least the 12-month period preceding the effective date
//                   of this Privacy Notice), information about you for the following
//                   reasons:
//                 </p>
//                 <p Style="text-indent: 0pt;text-align: left;">
//                   <br />
//                 </p>
//                 <h4 Style="padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                   Performing, Improving and Developing our Services
//                 </h4>
//                 <ul id="l5">
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: justify;">
//                       Delivering the information and support you request,
//                       including technical notices, security alerts, and support
//                       and administrative messages such as to resolve disputes,
//                       collect fees, and provide assistance for problems with our
//                       Services or your Jobify account;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;text-align: justify;">
//                       Determining whether the Services are available in your
//                       country;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: justify;">
//                       Developing new products and services;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: justify;">
//                       Displaying your historical transaction information;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Improving, personalizing, and facilitating your use of our
//                       Services;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Processing or recording payment transactions;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Providing, maintaining and improving our Services, including
//                       our website;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Doing internal research, measuring, tracking, and analyzing
//                       trends and usage; and
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Otherwise providing you with the products and features you
//                       choose to use.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       Communicating with You About our Services
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Sending you surveys and getting your feedback about our
//                       Services;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Providing information about and promoting our Services to
//                       you; and
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       Sending you information we think you may find useful or
//                       which you have requested from us about our products and
//                       services.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       Protecting our Services and Maintaining a Trusted
//                       Environment
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       Conducting investigations, complying with and enforcing
//                       applicable laws, regulations, legal requirements, and
//                       industry standards, and responding to lawful requests for
//                       information from the government or to valid legal process;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Contacting you to resolve disputes, collect fees, and help
//                       you with our Services;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Debugging to identify and fix errors that impair how our
//                       Services function;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Making sure you follow our Terms of Service or other
//                       applicable agreements or policies;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       Investigating, detecting, preventing, recovering from or
//                       reporting fraud, misrepresentations, security breaches or
//                       incidents, other potentially prohibited, malicious, or
//                       illegal activities, or to otherwise help protect your
//                       account, including to dispute chargebacks on your behalf;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       Protecting your, our, our customers’, or your customers’
//                       rights or property, or the security or integrity of our
//                       Services;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Verifying or maintaining the quality and safety of our
//                       Services; and
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Verifying your identity (e.g., through government-issued
//                       identification numbers).
//                     </p>
//                     <h4 Style="padding-top: 3pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       Advertising and Marketing
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Marketing our Services to you; and
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       Communicating with you about opportunities, products,
//                       services, contests, promotions, discounts, incentives,
//                       surveys, and rewards offered by us and select partners.
//                     </p>
//                     <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       If we send you marketing emails, each email will have
//                       instructions on how you can “opt out” of getting future
//                       marketing from us.
//                     </p>
//                     <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       To learn about what choices you have about interest-based
//                       advertising and cross-device tracking, please see below.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       Other Uses
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       For any other reason we may tell you about from time to
//                       time.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                   </li>
//                 </ul>
//               </li>
//               <li data-list-text="5.">
//                 <h3 Style="padding-top: 5pt;padding-left: 20pt;text-indent: -13pt;text-align: left;">
//                   WHEN AND WITH WHOM WE SHARE YOUR INFORMATION
//                 </h3>
//                 <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                   We may share the personal information described in the
//                   “Information We Collect About You” section with the following
//                   categories of service providers and third parties:
//                 </p>
//                 <p Style="text-indent: 0pt;text-align: left;">
//                   <br />
//                 </p>
//                 <h4 Style="padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                   With Other Users of our Services with Whom You Interact
//                 </h4>
//                 <ul id="l6">
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       With other users of our Services with whom you interact
//                       through your own use of our Services. For example, we may
//                       share information with your customers when you make or
//                       accept a payment or money transfer using our Services.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       With our Affiliates and Group Companies
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       With our group companies and corporate affiliates, including
//                       Cash App and Afterpay, for the reasons outlined above. For
//                       example, we may share your information internally to
//                       understand how you engage with Block company products to
//                       help make our Services better for you and for everyone, and
//                       to help us build Services tailored to your preferences.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       With our Service Providers
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       With service providers who help us provide, maintain, and
//                       improve our Services;
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       With Advertising Partners
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p
//                       class="s2"
//                       Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                     >
//                       <a
//                         href="https://termsandprivacy.weebly.com/privacy-en.html#id.u5bod3e9qkkj"
//                         Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                         target="_blank"
//                       >
//                         We share your information with advertising partners for
//                         interest-based advertising or “targeted advertising”
//                         campaigns, and with advertising partners that help analyze
//                         our site, run contests, special offers, or other events or
//                         activities, and track metrics on our behalf, on behalf of
//                         your Square Seller, or in connection with our Services.
//                         For more information on how we share your information for
//                         advertising and analytics purposes through the use of
//                         cookies please see{" "}
//                       </a>
//                       here
//                       <a
//                         href="https://termsandprivacy.weebly.com/privacy-en.html#id.rhfyzw11oift"
//                         Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                         target="_blank"
//                       >
//                         . For how to opt-out of targeted advertising and for other
//                         choices you may have relating to receiving advertising,
//                         please see{" "}
//                       </a>
//                       here
//                       <span Style=" color: #202020;">
//                         . Although we disclose your personal information to third
//                         parties as described in this section, we do not sell your
//                         personal information.
//                       </span>
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       Business Transfers and Corporate Changes
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       To a subsequent owner, co-owner, or operator of one or more
//                       of the Services; or
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       If we do or try to do a corporate merger, consolidation, or
//                       restructuring (including during due diligence and
//                       negotiation of these); the sale of substantially all of our
//                       stock and/or assets; the financing, acquisition,
//                       divestiture, or dissolution of all or a portion of our
//                       business; or other corporate change.
//                     </p>
//                     <h4 Style="padding-top: 3pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       Safety and Compliance with Law
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       If we believe that disclosure is reasonably necessary (i) to
//                       comply with any applicable law, regulation, legal process or
//                       governmental request (e.g., from creditors, tax authorities,
//                       law enforcement agencies, in response to a garnishment,
//                       levy, or lien notice, etc.); (ii) to establish, exercise or
//                       defend our legal rights; (iii) to enforce or comply with our
//                       General Terms or other applicable agreements or policies;
//                       (iv) to protect our or our customers’ rights or property, or
//                       the security or integrity of our Services; (v) for an
//                       investigation of suspected or actual illegal activity; or
//                       (vi) to protect us, users of our Services or the public from
//                       harm, fraud, or potentially prohibited or illegal
//                       activities.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       With Others With Your Consent
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       With your consent. For example:
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       At your direction or as described at the time you agree to
//                       share; or
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       When you authorize a third party application or website to
//                       access your information.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       Aggregated and Anonymized Information
//                     </h4>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 7pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       We also may share (within our group of companies or
//                       affiliates, or with service providers or other third
//                       parties) aggregated and anonymized information that does not
//                       specifically identify you or any individual user of our
//                       Services.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h3 Style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       COOKIES, OTHER SIMILAR AUTOMATED TECHNOLOGIES, AND
//                       ADVERTISING
//                     </h3>
//                     <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       When you interact with our online services, or open emails
//                       we send you, we obtain certain information using automated
//                       technologies, such as cookies, web server logs, web beacons,
//                       and other technologies. A “cookie” is a text file that
//                       websites send to a visitor’s computer or other
//                       internet-connected device to uniquely identify the visitor’s
//                       browser or to store information or settings in the browser.
//                       A “web beacon,” also known as an internet tag, pixel tag, or
//                       clear GIF, is a tiny graphic image that may be used in our
//                       websites or emails.
//                     </p>
//                     <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       We use these automated technologies to collect your device
//                       information, internet activity information, and inferences
//                       as described above. These technologies help us to:
//                     </p>
//                     <ul id="l7">
//                       <li data-list-text="●">
//                         <p Style="padding-top: 6pt;padding-left: 17pt;text-indent: -10pt;text-align: left;">
//                           Remember your information so you do not have to re-enter
//                           it;
//                         </p>
//                       </li>
//                       <li data-list-text="●">
//                         <p Style="padding-top: 9pt;padding-left: 17pt;text-indent: -10pt;text-align: left;">
//                           Track and understand how you use and interact with our
//                           online services and emails;
//                         </p>
//                       </li>
//                       <li data-list-text="●">
//                         <p Style="padding-top: 9pt;padding-left: 17pt;text-indent: -10pt;text-align: left;">
//                           Tailor our online services to your preferences;
//                         </p>
//                       </li>
//                       <li data-list-text="●">
//                         <p Style="padding-top: 9pt;padding-left: 17pt;text-indent: -10pt;text-align: left;">
//                           Measure how useful and effective our services and
//                           communications are to you; and
//                         </p>
//                       </li>
//                       <li data-list-text="●">
//                         <p Style="padding-top: 9pt;padding-left: 17pt;text-indent: -10pt;text-align: left;">
//                           Otherwise manage and enhance our products and services.
//                         </p>
//                         <p Style="padding-top: 9pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                           We set some of these automated technologies ourselves,
//                           but others are set by third parties who deliver services
//                           on our behalf. For example, we may use other companies’
//                           web analytics services (described in the “Third-Party
//                           Analytics Services” section), which use automated
//                           technologies to help us evaluate how customers use our
//                           websites. Some of these technologies may also be set by
//                           third parties that help us advertise our products and
//                           services to you as informed by your interests, based on
//                           information collected from your activity on the web,
//                           including but not limited to, browsing or purchasing
//                           products on or through our websites or on third party
//                           websites or your activity on mobile sites and
//                           applications. This advertising approach is called
//                           interest-based advertising or “targeted advertising.”
//                           You thus may see certain ads on other websites because
//                           we use these third-party services to show you Jobify ads
//                           that may be tailored to your individual interests.
//                         </p>
//                         <p Style="text-indent: 0pt;text-align: left;">
//                           <br />
//                         </p>
//                         <p Style="padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                           Your browser can alert you when cookies are placed on
//                           your device, and you can stop or disable them via your
//                           browser settings. More information on how to manage
//                           these settings on common browsers and devices are below.
//                         </p>
//                         <ul id="l8">
//                           <li data-list-text="•">
//                             <p
//                               class="s2"
//                               Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;text-align: left;"
//                             >
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.google.com/chrome/answer/95647%3Fco%3DGENIE.Platform%253DDesktop%26hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672968957240547&amp;usg=AOvVaw32R0KFKYBTe9dRFxgksGoQ"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 Manage cookie settings in{" "}
//                               </a>
//                               Chrome{" "}
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.google.com/chrome/answer/95647%3Fco%3DGENIE.Platform%253DAndroid%26hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672968957241094&amp;usg=AOvVaw0ZMTQJN9fDTvR85LxLgrdA"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 and{" "}
//                               </a>
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.google.com/chrome/answer/95647%3Fco%3DGENIE.Platform%253DAndroid%26hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672968957241094&amp;usg=AOvVaw0ZMTQJN9fDTvR85LxLgrdA"
//                                 class="a"
//                                 target="_blank"
//                               >
//                                 Chrome{" "}
//                               </a>
//                               Android{" "}
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.google.com/chrome/answer/95647%3Fco%3DGENIE.Platform%253DiOS%26hl%3Den%26oco%3D0&amp;sa=D&amp;source=editors&amp;ust=1672968957241490&amp;usg=AOvVaw2DCL0woU_yAbm3pbqSifb9"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 and{" "}
//                               </a>
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.google.com/chrome/answer/95647%3Fco%3DGENIE.Platform%253DiOS%26hl%3Den%26oco%3D0&amp;sa=D&amp;source=editors&amp;ust=1672968957241490&amp;usg=AOvVaw2DCL0woU_yAbm3pbqSifb9"
//                                 class="a"
//                                 target="_blank"
//                               >
//                                 Chrome{" "}
//                               </a>
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.google.com/chrome/answer/95647%3Fco%3DGENIE.Platform%253DiOS%26hl%3Den%26oco%3D0&amp;sa=D&amp;source=editors&amp;ust=1672968957241490&amp;usg=AOvVaw2DCL0woU_yAbm3pbqSifb9"
//                                 target="_blank"
//                               >
//                                 iOS
//                               </a>
//                             </p>
//                           </li>
//                           <li data-list-text="•">
//                             <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop%3Fredirectlocale%3Den-US%26redirectslug%3Denable-and-disable-cookies-website-preferences&amp;sa=D&amp;source=editors&amp;ust=1672968957241935&amp;usg=AOvVaw0BahuzllY5CPpduYEbuvLw"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 Manage cookie settings in{" "}
//                               </a>
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop%3Fredirectlocale%3Den-US%26redirectslug%3Denable-and-disable-cookies-website-preferences&amp;sa=D&amp;source=editors&amp;ust=1672968957241935&amp;usg=AOvVaw0BahuzllY5CPpduYEbuvLw"
//                                 target="_blank"
//                               >
//                                 Firefox
//                               </a>
//                             </p>
//                           </li>
//                           <li data-list-text="•">
//                             <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d&amp;sa=D&amp;source=editors&amp;ust=1672968957242371&amp;usg=AOvVaw2Q604fOZWBbF4e8KC1_c_J"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 Manage cookie settings in{" "}
//                               </a>
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d&amp;sa=D&amp;source=editors&amp;ust=1672968957242371&amp;usg=AOvVaw2Q604fOZWBbF4e8KC1_c_J"
//                                 target="_blank"
//                               >
//                                 Internet Explorer
//                               </a>
//                             </p>
//                           </li>
//                           <li data-list-text="•">
//                             <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-0&amp;sa=D&amp;source=editors&amp;ust=1672968957242851&amp;usg=AOvVaw1UuDc7NdReKpEimJsvrXsu"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 Manage cookie settings in{" "}
//                               </a>
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-0&amp;sa=D&amp;source=editors&amp;ust=1672968957242851&amp;usg=AOvVaw1UuDc7NdReKpEimJsvrXsu"
//                                 target="_blank"
//                               >
//                                 Microsoft Edge
//                               </a>
//                             </p>
//                           </li>
//                           <li data-list-text="•">
//                             <p
//                               class="s2"
//                               Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;"
//                             >
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.apple.com/en-gb/guide/safari/sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1672968957243399&amp;usg=AOvVaw2LiCD03eD-5Q5fZIGPZ54z"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 Manage cookie settings in{" "}
//                               </a>
//                               Safari{" "}
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.apple.com/en-gb/HT201265&amp;sa=D&amp;source=editors&amp;ust=1672968957243790&amp;usg=AOvVaw1QwzYQMFelo1JHc8k-ODAE"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 and{" "}
//                               </a>
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.apple.com/en-gb/HT201265&amp;sa=D&amp;source=editors&amp;ust=1672968957243790&amp;usg=AOvVaw1QwzYQMFelo1JHc8k-ODAE"
//                                 class="a"
//                                 target="_blank"
//                               >
//                                 Safari{" "}
//                               </a>
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.apple.com/en-gb/HT201265&amp;sa=D&amp;source=editors&amp;ust=1672968957243790&amp;usg=AOvVaw1QwzYQMFelo1JHc8k-ODAE"
//                                 target="_blank"
//                               >
//                                 iOS
//                               </a>
//                             </p>
//                             <p Style="padding-top: 9pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                               <a
//                                 href="http://www.allaboutcookies.org./"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 Please note, however, that without cookies all of
//                                 the features of our online services may not work
//                                 properly. If you use a mobile device, you can
//                                 manage how your device and browser share certain
//                                 device data by changing the privacy and security
//                                 settings on your mobile device. You can learn more
//                                 about cookies and how to manage your preferences
//                                 by visiting
//                               </a>
//                               <a
//                                 href="http://www.allaboutcookies.org./"
//                                 target="_blank"
//                               >
//                                 www.allaboutcookies.org
//                               </a>
//                             </p>
//                             <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                               Please also see the “Your Choices” section for other
//                               ways to opt-out of interest-based or targeted
//                               advertising.
//                             </p>
//                             <p Style="padding-top: 9pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: justify;">
//                               Certain web browsers allow you to instruct your
//                               browser to respond to Do Not Track (“DNT”) signals
//                               to websites you visit, informing those sites that
//                               you do not want your online activities to be
//                               tracked. At this time, our websites are not designed
//                               to respond to DNT signals or similar mechanisms from
//                               browsers.
//                             </p>
//                             <p Style="text-indent: 0pt;text-align: left;">
//                               <br />
//                             </p>
//                             <h4 Style="padding-left: 6pt;text-indent: 0pt;text-align: justify;">
//                               THIRD-PARTY ANALYTICS SERVICES
//                             </h4>
//                             <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                               Our analytics partners help us with our online
//                               services, such as Google Analytics, BugSnag, and
//                               Crashlytics. The analytics providers that administer
//                               these services use technologies such as cookies, web
//                               beacons, and web server logs to help us analyze how
//                               you use our online services. We may disclose your
//                               site-use information (including IP address) to these
//                               analytics providers, and other service providers who
//                               use the information to help us figure out how you
//                               and others use our online services.
//                             </p>
//                           </li>
//                           <li data-list-text="•">
//                             <p
//                               class="s2"
//                               Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                             >
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//marketingplatform.google.com/about/&amp;sa=D&amp;source=editors&amp;ust=1672968957245666&amp;usg=AOvVaw2e0aq3NbmZSjJIHwtxc-tl"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 To learn more about Google Analytics and how to
//                                 opt out, please visit{" "}
//                               </a>
//                               https://marketingplatform.google.com/about/{" "}
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//support.google.com/analytics/answer/181881%3Fhl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672968957245974&amp;usg=AOvVaw1HPDOtOmC_58w8tjwxK15L"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 or
//                               </a>
//                               https://support.google.com/analytics/answer/181881?hl=en{" "}
//                               <span Style=" color: #202020;">.</span>
//                             </p>
//                           </li>
//                           <li data-list-text="•">
//                             <p
//                               class="s2"
//                               Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;"
//                             >
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//www.facebook.com/help/325807937506242/&amp;sa=D&amp;source=editors&amp;ust=1672968957246404&amp;usg=AOvVaw0mQmVWFP7pPGlgIwnAre2k"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 To learn more about how Facebook uses your data
//                                 please visit
//                               </a>
//                               https://www.facebook.com/help/325807937506242/{" "}
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//www.facebook.com/about/ads&amp;sa=D&amp;source=editors&amp;ust=1672968957246638&amp;usg=AOvVaw1QIb6VmXQNMkcZsDViC9Xn"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 or log on to your Facebook account and access your
//                                 settings. To understand more about Facebook
//                                 advertising please see here
//                               </a>
//                               https://www.facebook.com/about/ads{" "}
//                               <span Style=" color: #202020;">.</span>
//                             </p>
//                           </li>
//                           <li data-list-text="•">
//                             <p
//                               class="s2"
//                               Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;text-align: left;"
//                             >
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//docs.bugsnag.com/legal/privacy-policy/&amp;sa=D&amp;source=editors&amp;ust=1672968957246979&amp;usg=AOvVaw32eLfV9HLswHJYAlX_c5jZ"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 To learn more about BugSnag, please visit
//                               </a>
//                               https://docs.bugsnag.com/legal/privacy-policy/{" "}
//                               <span Style=" color: #202020;">.</span>
//                             </p>
//                           </li>
//                           <li data-list-text="•">
//                             <p
//                               class="s2"
//                               Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;"
//                             >
//                               <a
//                                 href="https://www.google.com/url?q=https%3A//fabric.io/terms&amp;sa=D&amp;source=editors&amp;ust=1672968957247501&amp;usg=AOvVaw0U_sQU_PAbuGEYlOUSZvz6"
//                                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                                 target="_blank"
//                               >
//                                 To learn more about Crashlytics, please visit
//                               </a>
//                               https://fabric.io/terms{" "}
//                               <span Style=" color: #202020;">.</span>
//                             </p>
//                           </li>
//                         </ul>
//                       </li>
//                     </ul>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                   </li>
//                 </ul>
//               </li>
//               <li data-list-text="6.">
//                 <h3 Style="padding-top: 5pt;padding-left: 20pt;text-indent: -13pt;text-align: left;">
//                   HOW LONG WE KEEP YOUR INFORMATION
//                 </h3>
//                 <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                   We keep your information as long as is necessary for the
//                   purposes identified in this Privacy Notice, including to provide
//                   you the Services, to comply with applicable law and legal
//                   obligations, to defend our legal rights, property, and users.
//                   Even after you deactivate your account, we may retain copies of
//                   information about you and any transactions or Services in which
//                   you may have participated for a period of time consistent with
//                   certain criteria, such as applicable law, applicable statute of
//                   limitations or as we believe is reasonably necessary to comply
//                   with applicable law, regulation, legal process, or governmental
//                   request, to detect or prevent fraud, to collect fees owed, to
//                   resolve disputes, to address problems with our Services, to
//                   assist with investigations, to enforce our General Terms or
//                   other applicable agreements or policies, or to take any other
//                   actions consistent with applicable law.
//                 </p>
//                 <p Style="text-indent: 0pt;text-align: left;">
//                   <br />
//                 </p>
//               </li>
//               <li data-list-text="7.">
//                 <h3 Style="padding-left: 18pt;text-indent: -11pt;text-align: left;">
//                   YOUR CHOICES
//                 </h3>
//                 <h4 Style="padding-top: 7pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                   Your Personal Information Rights
//                 </h4>
//                 <p Style="padding-top: 3pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                   Depending on the jurisdiction in which you reside, you may be
//                   entitled under applicable law to request:
//                 </p>
//                 <ul id="l9">
//                   <li data-list-text="•">
//                     <p Style="padding-top: 8pt;padding-left: 22pt;text-indent: -11pt;line-height: 124%;text-align: left;">
//                       Access to your personal information, including: (1) the
//                       categories of personal information described above that we
//                       have collected about you and the categories of sources from
//                       which we collected such personal information;
//                     </p>
//                     <p Style="padding-left: 22pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       (2) the business or commercial purposes for collecting or
//                       sharing such personal information; (3) the categories of
//                       personal information about you that we have disclosed to
//                       third parties for a business purpose; (4) the categories of
//                       third parties to whom we have disclosed such personal
//                       information; and (5) in portable format, the specific pieces
//                       of personal information we have collected about you.
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 4pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Deletion of the personal information we have collected from
//                       you;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       Correction of the personal information we have collected
//                       about you;
//                     </p>
//                   </li>
//                   <li data-list-text="•">
//                     <p Style="padding-top: 6pt;padding-left: 22pt;text-indent: -11pt;text-align: left;">
//                       To opt-out of the sharing of your personal information for
//                       purposes of targeted advertising.
//                     </p>
//                     <p
//                       class="s2"
//                       Style="padding-top: 9pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;"
//                     >
//                       <a
//                         href="mailto:hello@jobify.club"
//                         Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                         target="_blank"
//                       >
//                         You can access, change, or correct information that you
//                         have provided by logging into your Jobify account at any
//                         time. You may also submit an access, deletion, or
//                         correction request by emailing us at{" "}
//                       </a>
//                       hello@jobify.club
//                       <a
//                         href="mailto:hello@jobify.club"
//                         Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                         target="_blank"
//                       >
//                         . Once we receive your request, we will verify it by
//                         requesting that you confirm certain personal information
//                         associated with your account. You may also be entitled to
//                         submit a request through an authorized agent. You may also
//                         email us{" "}
//                       </a>
//                       hello@jobify.club
//                       <span Style=" color: #202020;">
//                         to appeal our denial of any of your access, deletion, or
//                         correction requests.
//                       </span>
//                     </p>
//                     <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       You can exercise your right to opt-out of targeted
//                       advertising by clicking the “Opt-Out of Interest-Based
//                       Advertising” link in the footer of any page on our browser
//                       site.
//                     </p>
//                     <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       Although some of the information we collect and process
//                       about you may be considered sensitive personal information,
//                       we only process such information for purposes authorized by
//                       law, such as to provide services you request from us or to
//                       verify your information.
//                     </p>
//                     <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       We will not discriminate against you if you exercise these
//                       privacy rights, nor will we deny, charge different prices
//                       for, or provide a different quality of goods or services if
//                       you choose to exercise these rights.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       Deactivating Your Account
//                     </h4>
//                     <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       If you wish to deactivate your account, you can do so by
//                       logging into your Jobify account or by emailing us using the
//                       contact details provided below.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       Location Information
//                     </h4>
//                     <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       In order to provide certain Services, we may request access
//                       to location information, including precise geolocation
//                       information collected from your device. If you do not
//                       consent to the collection of this information, certain
//                       Services will not function properly and you will not be able
//                       to use those Services. You can stop our collection of
//                       location information at any time by changing the preferences
//                       on your mobile device. If you do so, some of our mobile
//                       applications will no longer function. You also may stop our
//                       collection of location information via mobile application by
//                       following the standard uninstall process to remove all
//                       Jobify mobile applications from your device.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-left: 6pt;text-indent: 0pt;text-align: left;">
//                       Promotional Communications
//                     </h4>
//                     <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       You can opt out of receiving promotional messages from
//                       Jobify by following the instructions in those messages, by
//                       informing the caller that you would not like to receive
//                       future promotional calls, or by changing your notification
//                       settings by logging into your Jobify account. You may only
//                       opt out of text messages from Jobify by replying STOP.
//                       Opting out of receiving communications may impact your use
//                       of the Services. If you decide to opt out, we may still send
//                       you non-promotional communications, such as digital receipts
//                       and messages about your account or our ongoing business
//                       relations.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                     <h4 Style="padding-left: 6pt;text-indent: 0pt;line-height: 118%;text-align: left;">
//                       Additional Choices for Third-Party Online Behavioral or
//                       Interest-Based Advertising Services on Websites (including
//                       Mobile Websites)
//                     </h4>
//                     <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       Some of the advertising platforms that we use to collect
//                       information from or about you in order to provide more
//                       relevant advertising to you on websites participate in the
//                       DAA Self-Regulatory Program for Online Behavioral
//                     </p>
//                     <p Style="padding-top: 3pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       Advertising. This program offers a centralized location
//                       where users can make choices about the use of their
//                       information for online behavioral advertising.
//                     </p>
//                     <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       Some of the advertisers, advertising technology companies,
//                       and service providers that perform advertising-related
//                       services for us may be members of the Network Advertising
//                       Initiative (NAI), a cooperative of online marketing
//                       companies that offers a centralized tool for opting out of
//                       interest-based advertising delivered to each of its member
//                       companies.
//                     </p>
//                     <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       Additional Choices for Third-Party Online Behavioral or
//                       Interest-Based Advertising Services Within Mobile
//                       Applications
//                     </p>
//                     <p Style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                       On your mobile device, you may also go to your device
//                       settings and select “Limit Ad Tracking” (for iOS devices),
//                       or “Opt out of Interest-Based Ads” (for Android devices),
//                       which will allow you to limit our use of information
//                       collected from or about your mobile device (such as precise
//                       location data) for the purposes of serving online behavioral
//                       advertising to you.
//                     </p>
//                     <p Style="text-indent: 0pt;text-align: left;">
//                       <br />
//                     </p>
//                   </li>
//                 </ul>
//               </li>
//               <li data-list-text="8.">
//                 <h3 Style="padding-left: 20pt;text-indent: -13pt;text-align: left;">
//                   CHILDREN’S PERSONAL INFORMATION
//                 </h3>
//                 <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                   Our Services are general audience services not directed at
//                   children under the age of 13. We do not knowingly sell or share
//                   the personal information of children under the age of 13. If we
//                   learn that any information we collect has been provided by a
//                   child under the age of 13, we will promptly delete that
//                   information.
//                 </p>
//                 <p Style="text-indent: 0pt;text-align: left;">
//                   <br />
//                 </p>
//               </li>
//               <li data-list-text="9.">
//                 <h3 Style="padding-left: 20pt;text-indent: -13pt;text-align: left;">
//                   HOW WE SECURE INFORMATION
//                 </h3>
//                 <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                   We take reasonable measures, including administrative,
//                   technical, and physical safeguards, to protect your information
//                   from loss, theft, and misuse, and unauthorized access,
//                   disclosure, alteration, and destruction. Nevertheless, the
//                   internet is not a 100% secure environment, and we cannot
//                   guarantee absolute security of the transmission or storage of
//                   your information. We hold information about you both at our own
//                   premises and with the assistance of third-party service
//                   providers.
//                 </p>
//                 <p Style="text-indent: 0pt;text-align: left;">
//                   <br />
//                 </p>
//               </li>
//               <li data-list-text="10.">
//                 <h3 Style="padding-left: 26pt;text-indent: -19pt;text-align: left;">
//                   STORAGE AND PROCESSING
//                 </h3>
//                 <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                   We may, and we may use third-party service providers to, process
//                   and store your information in the United States, Canada, Japan,
//                   the European Union, and other countries.
//                 </p>
//                 <p Style="text-indent: 0pt;text-align: left;">
//                   <br />
//                 </p>
//               </li>
//               <li data-list-text="11.">
//                 <h3 Style="padding-left: 23pt;text-indent: -16pt;text-align: left;">
//                   CHANGES TO THIS PRIVACY NOTICE
//                 </h3>
//                 <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//                   We may amend this Privacy Notice from time to time by posting a
//                   revised version and updating the “Effective Date” above. The
//                   revised version will be effective on the “Effective Date”
//                   listed. We will provide you with reasonable prior notice of
//                   material changes in how we use your information, including by
//                   email, if you have provided one. If you disagree with these
//                   changes, you may cancel your account at any time. If you keep
//                   using our Services, you consent to any amendment of this Privacy
//                   Notice.
//                 </p>
//                 <p Style="text-indent: 0pt;text-align: left;">
//                   <br />
//                 </p>
//               </li>
//               <li data-list-text="12.">
//                 <h3 Style="padding-left: 26pt;text-indent: -19pt;text-align: left;">
//                   HOW TO CONTACT US
//                 </h3>
//               </li>
//             </ol>
//             <p Style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;line-height: 187%;text-align: left;">
//               <a
//                 href="mailto:hello@jobify.club"
//                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                 target="_blank"
//               >
//                 Please contact our privacy team with any questions or concerns
//                 regarding this Privacy Notice: Email:{" "}
//               </a>
//               <a href="mailto:hello@jobify.club" target="_blank">
//                 hello@jobify.club
//               </a>
//             </p>
//             <p Style="padding-left: 6pt;text-indent: 0pt;line-height: 11pt;text-align: left;">
//               <a
//                 href="mailto:hello@jobify.club"
//                 Style=" color: #202020; font-family:Tahoma, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
//                 target="_blank"
//               >
//                 Help Center:{" "}
//               </a>
//               <a href="mailto:hello@jobify.club" target="_blank">
//                 hello@jobify.club
//               </a>
//             </p>
//             <p Style="padding-top: 9pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//               If you have any questions or concerns regarding our privacy notice,
//               or if you believe our privacy notice or applicable laws relating to
//               the protection of your personal information have not been respected,
//               you may file a complaint with
//             </p>
//             <p Style="padding-top: 3pt;padding-left: 6pt;text-indent: 0pt;line-height: 124%;text-align: left;">
//               our privacy team listed above. We will respond to let you know when
//               you can expect a further response. We may request additional details
//               from you regarding your concerns and may need to engage or consult
//               with other parties in order to investigate and address your issue.
//               We may keep records of your request and any resolution.
//             </p>
//           </div>
//       </div>
//       <HomeFooter/>
//     </>
//   );
// }
