// CodeEditor.jsx
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { encode as base64_encode } from "base-64";
import { confirmAlert } from "react-confirm-alert";
import axiosInstance from "../axiosInstance";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Split from "@uiw/react-split";

import {
  updateQuestions,
  submittedQuestions,
  markQuestionAttempted,
} from "../redux/slices/questions/questionSlice";
import {
  reviewSubmit,
  runCodeAndSubmitRequest,
  runCodeRequest,
  submitSolution,
} from "./../utilities/requests/runCodeUtility";
import { DEFAULT_SNIPPET } from "../Questions/questions";
import { DEFAULT_QUESTION } from "../basic";
import { useAppSelector } from "../redux/hooks";
import CodeEditor from "./CodeEditor";
import EditorPagination from "./CodeEditor/EditorPagination";
import MCQPage from "./CodeEditor/MCQPage";
import Loader from "./common/Loader";
import TestCase from "./CodeEditor/TestCase";
// Add other modes as required
import "react-toastify/dist/ReactToastify.css";
import "./Editor.scss";
import AppNav from "./AppNav";
import PlayIcon from "../assets/images/play.svg";
import DropdownFilter from "./common/DropdownFilter";
import { fetchQuestionById } from "../redux/thunks/questionThunks";
import { selectQuestionDetails } from "../redux/slices/questions/questionSlice";
import { fetchLanguages } from "../redux/thunks/languageThunks";
import { selectLanguages } from "../redux/slices/languages/languageSlice";
import axios from "axios";

const LANGUAGE_MODE_MAP = {
  "JavaScript (NodeJs 12.14.0)": "javascript",
  "Java (OpenJDK 13.0.1)": "java",
  "Python (3.8.1)": "python",
  "C++ (GCC 9.2.0)": "cpp",
  "C (GCC 9.2.0)": "c",
  "Ruby (2.7.0)": "ruby",
  "Swift (5.2.3)": "swift",
  "Go (1.13.5)": "go",
  "Scala (2.13.2)": "scala",
  "Rust (1.40.0)": "rust",
  "PHP (7.4.1)": "php",
  "TypeScript (3.7.4)": "typescript",
};

const getLanguageMode = (fullName) => {
  return LANGUAGE_MODE_MAP[fullName] || fullName.split(" ")[0].toLowerCase();
};

const Editor = () => {
  const { theme, user } = useAppSelector((state) => state.auth);

  const [time, setTime] = useState(() => {
    return parseInt(localStorage.getItem("time") || 905); // changed 1 hr to 15 min
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const minutes = Math.floor(time / 60);
  // const seconds = time % 60;

  const [languageId, setLanguageId] = useState(null);
  const [sourceCode, setSourceCode] = useState(
    DEFAULT_SNIPPET["java"].codeSnippet
  );
  const location = useLocation();

  const { queId } = location.state;

  const [codeResult, setCodeResult] = useState(null);
  const [runsubmit, setRunSubmit] = useState(false);

  const [output, setOutput] = useState("");
  const [testMessageClassName, setTestMessageClassName] = useState("hidden");
  const [testCasesResultMessage, setTestCasesResultMessage] = useState("");
  const [isTestPassed, setIsTestPassed] = useState(false);
  const [isCodingRunning, setIsCodeRunning] = useState(false);
  const [activeQue, setActiveQue] = useState(queId);

  const [isTestCasesRunning, setIsTestCasesRunning] = useState(false);
  const [testCasesResult, setTestCasesResult] = useState([]);
  const [jobData, setJobData] = useState(null);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const languages = useSelector(selectLanguages);
  const [testInput, setTestInput] = useState("");
  const [testCaseActiveTab, setTestCaseActiveTab] = useState("Input");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCodeSubmission = useCallback(async () => {
    if (codeResult === null && hasAttemptedSubmit) {
      toast.error("Please Run code before Submit");
      return;
    }

    try {
      setIsSubmitting(true);
      const token = localStorage.getItem("auth");
      const candidateTestId = searchParams.get("candidateTestId");

      const payload = {
        input: base64_encode(sourceCode),
        codeLanguageId: languageId,
        candidateTestId: candidateTestId,
        questionId: activeQue,
      };

      const response = await axios.post(
        `https://api.assessmentpad.com/api/v4/candidate-tests/answers/submit?token=${token}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 202) {
        dispatch(
          markQuestionAttempted({
            questionId: activeQue,
            submission: {
              code: sourceCode,
              language: languageId,
              timestamp: new Date().toISOString(),
            },
          })
        );

        toast.success("Solution submitted successfully!");
        setIsModalOpen(true);
        handleCodeSubmit();
        return true;
      }
    } catch (error) {
      console.error("Submission error:", error);
      toast.error(error.response?.data?.message || "Failed to submit solution");
      return false;
    } finally {
      setIsSubmitting(false);
    }
  }, [activeQue, sourceCode, languageId, dispatch]);

  useEffect(() => {
    // Prompt confirmation when reload page is triggered
    window.onbeforeunload = (e) => {
      e.preventDefault();
      return "";
    };

    // Unmount the window.onbeforeunload event
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const { questions, questionType, questionDetails } = useSelector(
    (state) => state.questions
  );

  const notify = (message) => {
    toast.info(message);
  };

  useEffect(() => {
    const interval =
      time > 0 &&
      setInterval(() => {
        localStorage.setItem("time", time - 1);
        setTime(time - 1);
      }, 1000);
    if (time === 0) {
      // handleCodeSubmission();
    }
    if (time === 50) {
      notify("5 min left");
    }
    if (time === 180) {
      notify("3 minutes left");
    }

    return () => clearInterval(interval);
  }, [time, handleCodeSubmission]);

  useEffect(() => {
    if (runsubmit) {
      handleCodeSubmission().finally(() => setRunSubmit(false));
    }
  }, [runsubmit]);

  useEffect(() => {
    setIsTestPassed(false);
    if (testCasesResult?.length === 0) {
      return;
    }

    if (testCasesResult.allTestCasesPassed) {
      setIsTestPassed(true);
      setTestMessageClassName("all-testcases-passed");
      setTestCasesResultMessage(
        "Congratulations.. All initial test cases are passed."
      );
    } else if (testCasesResult.allTestCasesFailed) {
      setTestMessageClassName("all-testcases-failed");
      setTestCasesResultMessage("All initial test cases are failed.");
    } else {
      setTestMessageClassName("some-testcases-failed");
      setTestCasesResultMessage(
        `${testCasesResult.passedTestCount} out of ${testCasesResult.totalTestCount} initial test cases passed`
      );
    }
  }, [testCasesResult]);

  const checkValidJob = () => {
    const authData = localStorage.getItem("auth");
    const token = authData;
    const jobId = searchParams.get("jobId");

    axiosInstance
      .get(`v1/jobs/eligibility?jobPostUUID=${jobId}&token=${token}`, {
        headers: {
          accept: "*/*",
        },
      })
      .then((response) => {
        // Handle the response data here
        localStorage.setItem("jobId", jobId);
        // getJobInfo();
      })
      .catch((error) => {
        toast.error("Please contact us at hello@jobify.club");
        // navigate('/candidates-view');
      });
  };

  const getJobInfo = async () => {
    const authData = localStorage.getItem("auth");
    const token = authData;
    const jobId = searchParams.get("jobId");
    try {
      const response = await axiosInstance.get(
        `/v1/jobs/infos?jobId=${jobId}&token=${token}`
      );
      if (!response.data.questionText) {
        response.data.questionText = DEFAULT_QUESTION;
      }
      setJobData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  async function codesubmit() {
    const submitSolutionRecord = {
      candidateEmail: JSON.parse(localStorage.getItem("email")),
      codeInput: base64_encode(sourceCode),
      codeOutput: base64_encode(codeResult?.output),
      jobPostUUID: localStorage.getItem("jobId"),
      languageId: Number(languageId),
      isTestPassed: isTestPassed,
    };
    try {
      return await submitSolution(submitSolutionRecord).then(
        (response) => response.data
      );
    } catch (error) {
      throw error;
    }
  }

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguageId(selectedLanguage);
    const activeTemplate = questions
      ?.filter((que) => que["question"]["id"] == activeQue)[0]
      ["codeTemplateList"].filter(
        (code) =>
          code["codeLanguageId"] == "4ba0150b-bc71-4d2f-9a59-5418e57d89eb"
      )[0]["template"];
    // setSourceCode(DEFAULT_SNIPPET[selectedLanguage]?.codeSnippet || '');
    setSourceCode(atob(activeTemplate));
  };
  // Sample url: https://api.jobify.club/v1/video/send-complete?message=dhanushvimal001@gmail.com_jobId=2954271d-be7b-400d-92c7-c4399275893c
  const handleCodeSubmit = () => {
    const jobApplicationId = localStorage.getItem("jobApplicationId");
    const token = localStorage.getItem('auth')
    try {
      const response = axiosInstance.post(
        `/v1/video/send-complete?token=${token}&message=${user.email}_${jobApplicationId}`
      );
    } catch (error) {}
  };

  const onQueChange = (val) => {
    setActiveQue(val);
  };

  const filterQuestionUpdate = (selectedLanguageName) => {
    const selectedLang = languages.find(
      (lang) => lang.name === selectedLanguageName
    );
    console.log({ selectedLang });
    if (selectedLang) {
      setLanguageId(selectedLang.id);

      try {
        setSourceCode(atob(selectedLang.template));
      } catch (error) {
        console.error("Error decoding template:", error);
        toast.error("Failed to load template for selected language");
      }
    }
  };

  const questionDescription = useSelector((state) =>
    selectQuestionDetails(state, activeQue)
  );

  const fetchHere = async () => {
    const authData = localStorage.getItem("auth");
    const token = authData;

    try {
      const response = await axios.get(
        `http://api.assessmentpad.com/api/v4/questions/${activeQue}?token=${token}`
        // {
        //   headers: {
        //     token: token
        //   }
        // }
      );

      console.log("Question response:", response);
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Failed to fetch question details");
    }
  };

  useEffect(() => {
    console.log({ activeQue });
    const token = localStorage.getItem('auth')
    if (activeQue) {
      dispatch(fetchQuestionById({questionId:activeQue,token}));
      // fetchHere()
    }
  }, [activeQue, dispatch]);

  useEffect(() => {
    dispatch(fetchLanguages());
  }, [dispatch]);

  useEffect(() => {
    if (languages.length > 0) {
      // Find Java in the loaded languages
      const javaLanguage = languages.find((lang) =>
        lang.name.toLowerCase().includes("java")
      );

      if (javaLanguage) {
        setLanguageId(javaLanguage.id);
        try {
          setSourceCode(atob(javaLanguage.template));
        } catch (error) {
          console.error("Error decoding Java template:", error);
          toast.error("Failed to load Java template");
        }
      }
    }
  }, [languages]);

  const handleRunCode = async () => {
    if (!languageId) {
      toast.error("Please select a language first");
      return;
    }


    try {
      setIsCodeRunning(true);
      setOutput("Running code...");
      setTestCaseActiveTab("Output");

      const formattedInput = testInput.trim();
    const token = localStorage.getItem('auth')

      const payload = {
        codeLanguageId: languageId,
        sourceCode: base64_encode(sourceCode),
        input: base64_encode(formattedInput),
      };

      const response = await axios.post(
        `https://api.assessmentpad.com/api/v4/candidate-tests/run-code?token=${token}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const result = response.data;
        setCodeResult(result);

        let outputText;
        // Check if result.data exists and has output property
        if (result.data?.output) {
          try {
            outputText = atob(result.data.output);

            if (result.data.outputType === "RUNTIME_ERROR") {
              toast.error("Runtime error occurred");
            } else if (result.data.outputType === "COMPILATION_ERROR") {
              toast.error("Compilation error occurred");
            } else {
              toast.success("Code executed successfully!");
            }
          } catch (e) {
            console.error("Base64 decode error:", e);
            outputText = "Error decoding output";
            toast.error("Error processing output");
          }
        } else {
          outputText = "No output";
        }

        // Set the output regardless of the type
        setOutput(outputText || "No output generated");
      }
    } catch (error) {
      console.error("Run code error:", error);
      const errorMessage =
        error.response?.data?.message || "Failed to run code";
      setOutput(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsCodeRunning(false);
    }
  };

  return (
    <div className="editor-container-main">
      <div>
        <AppNav />
      </div>
      <div>
        {!questions ? (
          <Loader />
        ) : (
          <>
            <EditorPagination activeQue={activeQue} onQueChange={onQueChange} />

            {/* <div className="editor-header">
              <div className="header-actions">
                <div className="btn-timer">
                  {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </div>
              </div>
            </div> */}
            {questionDetails.questionType === "MCQ" ? (
              <MCQPage
                candidateTestId={searchParams.get("candidateTestId")}
                data={questionDetails}
              />
            ) : (
              <>
                <div className="editor-body">
                  <Split
                    mode="horizontal"
                    style={{
                      height: "100%",
                      boxShadow: "none",
                      alignSelf: "center",
                    }}
                  >
                    <div className="left-section">
                      <div className="question-main">
                        <div className="tab-header">
                          {questionDetails?.title}
                        </div>
                        <div className="tw-p-5">
                          {questionDetails.description || "Loading..."}
                        </div>
                      </div>
                    </div>
                    <div className="right-section">
                      <Split
                        mode="vertical"
                        style={{ height: "100%", boxShadow: "none" }}
                      >
                        <div className="code-editor">
                          <div
                            className="tab-header"
                            style={{
                              // height: 60,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Code</span>
                            <div className="action-buttons-container">
                              <button
                                className="run-button"
                                style={{
                                  marginLeft: "auto",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={handleRunCode}
                                disabled={isCodingRunning || !languageId}
                              >
                                <img
                                  style={{ marginRight: 10 }}
                                  src={PlayIcon}
                                />
                                {isCodingRunning ? "Running..." : "Run Code"}
                              </button>
                              <button
                                className="submit-button"
                                onClick={() => {
                                  setHasAttemptedSubmit(true);
                                  handleCodeSubmission();
                                  setIsModalOpen(true);
                                }}
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? "Submitting..." : "Submit"}
                              </button>
                            </div>
                          </div>

                          <div className="all-languages">
                            <DropdownFilter
                              name="languages"
                              styleClass="viewAllQnPageDropdown"
                              dropdownName={
                                languages.length === 0
                                  ? "Loading..."
                                  : languages.find(
                                      (lang) => lang.id === languageId
                                    )?.name || "Select Language"
                              }
                              dropdownOptions={languages.map(
                                (lang) => lang.name
                              )}
                              dropdownHandleClick={filterQuestionUpdate}
                              disabled={languages.length === 0}
                            />
                          </div>

                          <div style={{ height: "calc(100% - 90px)" }}>
                            <CodeEditor
                              language={
                                languageId
                                  ? getLanguageMode(
                                      languages.find(
                                        (lang) => lang.id === languageId
                                      )?.name || "java"
                                    )
                                  : "java"
                              }
                              sourceCode={sourceCode}
                              setSourceCode={setSourceCode}
                            />
                          </div>
                        </div>
                        <div
                          className="test-case-container"
                          id="accordionPapanelsStayOpennelsStayOpenExample"
                        >
                          <TestCase
                            codeOutput={output}
                            isRunning={isCodingRunning}
                            input={testInput}
                            onInputChange={setTestInput}
                            activeTab={testCaseActiveTab}
                            setActiveTab={setTestCaseActiveTab}
                          />
                        </div>
                      </Split>
                    </div>
                  </Split>
                </div>
                {/* <div className="action-buttons-container">
                  <button
                    className="run-button"
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={handleRunCode}
                    disabled={isCodingRunning || !languageId}
                  >
                    <img style={{ marginRight: 10 }} src={PlayIcon} />
                    {isCodingRunning ? "Running..." : "Run Code"}
                  </button>
                  <button
                    className="submit-button"
                    onClick={() => {
                      setHasAttemptedSubmit(true);
                      handleCodeSubmission();
                      setIsModalOpen(true);
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div> */}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Editor;
