import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { authReducer } from "./slices/auth/authSlice";
import questionReducer from "./slices/questions/questionSlice";
import languageReducer from "./slices/languages/languageSlice";
import jobsReducer from "./slices/jobsSlice";
import recruiterQuestionReducer from "./slices/questions/recruiterQuestionSlice";

export const store = configureStore({
  reducer: {
    questions: questionReducer,
    auth: authReducer,
    languages: languageReducer,
    jobs: jobsReducer,
    recruiterQuestions: recruiterQuestionReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
