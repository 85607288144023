import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questions: [],
  attemptedQuestions: {},
  questionType: "ALL",
  currentQuestionIndex: 0,
  loading: false,
  error: null,
  status: "idle",
  questionDetails: {},
  recruiterQuestions: [],
  testTime: null,
  startTime: null,
};

const questionSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setQuestions: (state, action) => {
      console.log({ action });
      state.questions = action.payload;
      state.status = "succeeded";
      state.loading = false;
      state.error = null;
    },
    setCurrentQuestionIndex: (state, action) => {
      state.currentQuestionIndex = action.payload;
    },
    clearQuestions: (state) => {
      state.questions = [];
      state.currentQuestionIndex = 0;
    },
    setQuestionType: (state, action) => {
      state.questionType = action.payload;
    },
    setQuestionDetails: (state, action) => {
      const { questionId, questionData } = action.payload;
      state.questionDetails = questionData;
    },
    setQuestionLoading: (state, action) => {
      state.loading = action.payload;
      state.status = action.payload ? "loading" : "idle";
    },
    setQuestionError: (state, action) => {
      state.error = action.payload;
      state.status = "failed";
      state.loading = false;
    },
    markQuestionAttempted: (state, action) => {
      const { questionId, submission } = action.payload;
      state.attemptedQuestions[questionId] = submission;
    },
    setRecruiterQuestions: (state, action) => {
      state.recruiterQuestions = action.payload;
    },
    setTestTime: (state, action) => {
      state.testTime = action.payload;
    },
    setStartTime: (state, action) => {
      state.startTime = action.payload;
    }
  },
});

export const {
  setQuestions,
  setCurrentQuestionIndex,
  clearQuestions,
  setQuestionType,
  setQuestionDetails,
  setQuestionLoading,
  setQuestionError,
  markQuestionAttempted,
  setRecruiterQuestions,
  setTestTime,
  setStartTime,
} = questionSlice.actions;

// Selectors
export const selectQuestions = (state) => state.questions.questions;
export const selectCurrentQuestionIndex = (state) =>
  state.questions.currentQuestionIndex;
export const selectQuestionDetails = (state, questionId) =>
  state.questions.questionDetails[questionId];
export const selectQuestionsStatus = (state) => state.questions.status;
export const selectQuestionsError = (state) => state.questions.error;
export const selectQuestionsLoading = (state) => state.questions.loading;

export default questionSlice.reducer;
