import { useState } from 'react';
import './rating-modal.css';

const Star = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
      className="star-path"
    />
  </svg>
);

const RatingModal = ({ isOpen, onClose, onSubmit }) => {
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  const handleSubmit = () => {
    onSubmit({ rating, feedback });
    onClose();
  };

  const handleRatingClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleMouseEnter = (hoveredValue) => {
    setHoveredRating(hoveredValue);
  };

  const handleMouseLeave = () => {
    setHoveredRating(0);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-card">
        <div className="modal-header">
          <h2>Rate your experience !</h2>
        </div>
        <div className="modal-content">
          <div className="star-rating">
            {[1, 2, 3, 4, 5].map((value) => (
              <button
                key={value}
                onClick={() => handleRatingClick(value)}
                onMouseEnter={() => handleMouseEnter(value)}
                onMouseLeave={handleMouseLeave}
                className="star-button"
              >
                <Star
                  className={`star-icon ${
                    value <= (hoveredRating || rating) ? "star-filled" : "star-empty"
                  }`}
                />
              </button>
            ))}
          </div>
         
          <div className="feedback-section">
            <label htmlFor="feedback">Your feedback (optional)</label>
            <textarea
              id="feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Tell us what you think..."
              rows="3"
            />
          </div>
          <div className="modal-actions">
            <button className="button-skip" onClick={onClose}>
              Skip
            </button>
            <button 
            style={{cursor:"pointer"}}
              className="submit-rating-button" 
              onClick={handleSubmit}
              // disabled={!rating}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingModal; 