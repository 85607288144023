import React, { useState } from "react";
import DropdownFilter from "../../common/DropdownFilter";
import "./categoryType.scss";

const CategoryType = ({ onChange }) => {
  const [questionType, setSelectedQuestionType] = useState("");

  const handleSelectCategory = (value) => {
    setSelectedQuestionType(value);
  };
  return (
    <DropdownFilter
      name="quetionType"
      styleClass="question-type"
      dropdownName={"Type"}
      dropdownOptions={["All", "Coding", "MCQ"]}
      dropdownHandleClick={onChange}
    />
  );
};

export default CategoryType;
