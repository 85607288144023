import axios from 'axios';
import { 
  setQuestionDetails, 
  setQuestionLoading,
  setQuestionError 
} from '../slices/questions/questionSlice';

export const fetchQuestionById = ({questionId,token}) => async (dispatch) => {
  try {
    dispatch(setQuestionLoading(true));
    
    console.log('Fetching question:', questionId);
    
    const response = await axios.get(
      `https://api.assessmentpad.com/api/v4/questions/${questionId}?token=${token}&isRecruiter=false`,
      // {
      //   headers: {
      //     token: token  
      //   }
      // }
    );
    
    console.log('Question response:', response);
    
    dispatch(setQuestionDetails({
      questionId,
      questionData: response.data.data
    }));
    
    dispatch(setQuestionLoading(false));
    
  } catch (error) {
    console.error('Error fetching question:', {
      message: error.message,
      response: error.response,
      config: error.config
    });
    
    dispatch(setQuestionError(
      error.response?.data?.message || error.message || 'Failed to fetch question'
    ));
    dispatch(setQuestionLoading(false));
  }
};
