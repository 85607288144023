import React from "react";
import { useSearchParams } from "react-router-dom";
import AppNav from "../AppNav";
import RecruiterQuestionLists from "./QuestionBank/RecruiterQuestionLIsts";
import TermsAndConditions from "./Settings/TermsAndConditions";
import PrivacyPolicy from "./Settings/PrivacyPolicy";
import CreateQuestions from "./CreateQuestions/CreateQuestions";
import CreateJobNew from "./CreateJobNew";
import LegalDocs from "../admin/LegalDocs";
import RecruiterCandidates from "./Candidates/RecruiterCandidates";
import If from "../If";

export default function Rhome() {
  const [searchParams] = useSearchParams();

  return (
    <div>
      <AppNav />
      <div className="recruiters-view py-4">
        <If condition={searchParams.get("tab") === "question-bank"}>
          <div className="h-100">
            <RecruiterQuestionLists />
          </div>
        </If>

        <If condition={searchParams.get("tab") === "create-job"}>
          <CreateJobNew />
        </If>

        <If condition={searchParams.get("tab") === "candidates"}>
          <RecruiterCandidates />
        </If>

        <If
          condition={
            searchParams.get("tab") === "create-question" ||
            searchParams.get("tab").includes("update-question")
          }
        >
          <CreateQuestions />
        </If>

        <If condition={searchParams.get("tab") == "terms"}>
          <TermsAndConditions />
        </If>

        <If condition={searchParams.get("tab") == "privacy"}>
          <PrivacyPolicy />
        </If>

        <If condition={searchParams.get("tab") === "legal-docs"}>
          <LegalDocs />
        </If>
      </div>
    </div>
  );
}
