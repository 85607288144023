import { useEffect, useMemo, useState } from "react";
import styles from "./questions-list.module.css";
import rightPaginationArrow from "../../../assets/images/rightPaginationArrow.svg";
import leftPaginationArrow from "../../../assets/images/leftPaginationArrow.svg";
import { useNavigate } from "react-router-dom";
import CategoryType from "./CategoryType";
import { useDispatch, useSelector } from "react-redux";
import { setRecruiterQuestions } from "../../../redux/slices/questions/questionSlice";
import axiosInstance from "../../../axiosInstance";
import Loader from "../../common/Loader";

const questionsPerPage = 10;

export default function QuestionsList() {
  const [buttonRange, setButtonRange] = useState({ start: 1, end: 10 });
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [questionType, setQuestionType] = useState("ALL");
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const questions = useSelector(
    (state) => state.questions.recruiterQuestions || []
  );

  useEffect(() => {
    const fetchQuestions = async () => {
      const token = JSON.parse(localStorage.getItem("auth"));
      const recruiterId = JSON.parse(localStorage.getItem("user")).recruiterId;
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(
          `/api/v4/questions?token=${token}`,
          {
            params: {
              page: currentPage - 1,
              count: questionsPerPage,
              recruiterId: recruiterId,
              // type: questionType !== 'ALL' ? questionType : undefined
            },
          }
        );
        dispatch(setRecruiterQuestions(response.data.data.data));
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.total);
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuestions();
  }, [currentPage, questionType, dispatch]);

  const filteredQuestionsList = useMemo(() => {
    if (!questions.length) return [];

    let filtered = [...questions];
    if (questionType.toUpperCase() !== "ALL") {
      filtered = filtered.filter(
        (elem) => elem.questionType === questionType.toUpperCase()
      );
    }

    return filtered;
  }, [questions, questionType]);

  const currentPageQuestions = useMemo(() => {
    return filteredQuestionsList;
  }, [filteredQuestionsList]);

  const handleNextRange = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);

      if (currentPage >= buttonRange.end) {
        setButtonRange((prev) => ({
          start: prev.start + 10,
          end: Math.min(prev.end + 10, totalPages),
        }));
      }
    }
  };

  const handlePrevRange = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);

      if (currentPage <= buttonRange.start) {
        setButtonRange((prev) => ({
          start: Math.max(prev.start - 10, 1),
          end: prev.start - 1,
        }));
      }
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (
      let i = buttonRange.start;
      i <= Math.min(buttonRange.end, totalPages);
      i++
    ) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? styles.active : ""}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <header className={styles.questionBankheader}>
        <h1>All questions</h1>
        <button
          onClick={() => navigate("/create-question")}
          className={styles.createButton}
        >
          +Create Question
        </button>
      </header>

      <div className={styles.listHeader}>
        <div className={styles.titleHeader}>TITLE</div>
        <div className={styles.typeHeader}>
          <CategoryType onChange={(type) => setQuestionType(type)} />
        </div>
        <div style={{ width: 55 }}></div>
      </div>

      <div className={styles.listContainer}>
        {currentPageQuestions.length > 0 ? (
          currentPageQuestions.map((question, index) => (
            <div key={question.id} className={styles.listItem}>
              <div className={styles.questionInfo}>
                {/* <span className={styles.number}>{question.id}.</span> */}
                <span className={styles.title}>
                  {(currentPage - 1) * questionsPerPage +
                    index +
                    1 +
                    ". " +
                    question.title +
                    " "}{" "}
                </span>
              </div>
              <div className={styles.type}>
                {question.questionType === "MCQ" ? "MCQ" : "Coding"}
              </div>
              <button
                className={styles.viewButton}
                onClick={() =>
                  navigate(`/questions/${question.id}`)
                }
              >
                View
              </button>
            </div>
          ))
        ) : questions.length > 0 ? (
          <h1 className="text-center text-warning">
            No questions match the selected filters
          </h1>
        ) : (
          <h1 className="text-center">No questions available</h1>
        )}
      </div>

      <div className={styles.questionBankPagination}>
        <div className={styles.paginationNoBtns}>
          {renderPaginationButtons()}
        </div>
        <div className={styles.paginationNextPrevBtn}>
          <button onClick={handlePrevRange} disabled={currentPage === 1}>
            <img src={leftPaginationArrow} alt="Previous" />
          </button>
          <button
            onClick={handleNextRange}
            disabled={currentPage === totalPages}
          >
            <img src={rightPaginationArrow} alt="Next" />
          </button>
        </div>
      </div>
    </div>
  );
}
