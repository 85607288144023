import { useState, useEffect } from "react";
import RichTextEditor from "../RTE/RichTextEditor";
import { convertToHTML } from "draft-convert";
import { convertFromRaw } from "draft-js";
import "./legalDocs.scss";
import { toast } from "react-toastify";

export default function LegalDocs() {
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [terms, setTerms] = useState("");
  const [activeTab, setActiveTab] = useState("privacy-policy");
  const [privacyConfigId, setPrivacyConfigId] = useState("");
  const [termsConfigId, setTermsConfigId] = useState("");

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(
          `https://api.assessmentpad.com/api/app-configs?token=${user._token}&configName=PRIVACY`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch privacy policy");
        }

        const data = await response.json();
        if (data && data.data[0]) {
          setPrivacyPolicy(data.data[0].data.description);
          setPrivacyConfigId(data.data[0].id);
        }
      } catch (error) {
        console.error("Error fetching privacy policy:", error);
      }
    };

    fetchPrivacyPolicy();
  }, []);
  console.log({ privacyPolicy });
  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(
          `https://api.assessmentpad.com/api/app-configs?token=${user._token}&configName=TERMS_AND_CONDITIONS`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch terms and conditions");
        }

        const data = await response.json();
        if (data && data.data[0]) {
          setTerms(data.data[0].data.description);
          setTermsConfigId(data.data[0].id);
        }
      } catch (error) {
        console.error("Error fetching terms and conditions:", error);
      }
    };

    fetchTerms();
  }, []);

  const convertContentToHTML = (content) => {
    try {
      if (typeof content === "string") {
        content = JSON.parse(content);
      }
      const contentState = convertFromRaw(content);
      return convertToHTML(contentState);
    } catch (error) {
      console.error("Error converting content to HTML:", error);
      return "";
    }
  };

  const handlePrivacySubmit = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const htmlContent = convertContentToHTML(privacyPolicy);

      const response = await fetch(
        `https://api.assessmentpad.com/api/app-configs/${privacyConfigId}?token=${user._token}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              description: htmlContent,
              title: "Privacy Policy",
              about: "Privacy Policy",
            },
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update privacy policy");
      }

      toast.success("Privacy Policy updated successfully");
    } catch (error) {
      console.error("Error updating privacy policy:", error);
      toast.error("Failed to update Privacy Policy");
    }
  };

  const handleTermsSubmit = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const htmlContent = convertContentToHTML(terms);

      const response = await fetch(
        `https://api.assessmentpad.com/api/app-configs/${termsConfigId}?token=${user._token}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              description: htmlContent,
              title: "Terms and Conditions",
              about: "Terms and Conditions",
            },
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update terms and conditions");
      }

      toast.success("Terms and Conditions updated successfully");
    } catch (error) {
      console.error("Error updating terms and conditions:", error);
      toast.error("Failed to update Terms and Conditions");
    }
  };

  return (
    <div className="legal-docs-container">
      <div className="tabs-section">
        <div className="tabs-container">
          <span
            className={`admin-tab ${
              activeTab === "privacy-policy" ? "active" : ""
            }`}
            onClick={() => setActiveTab("privacy-policy")}
          >
            <span className="tab-icon">📋</span>
            Privacy Policy
          </span>

          <span
            className={`admin-tab ${activeTab === "terms" ? "active" : ""}`}
            onClick={() => setActiveTab("terms")}
          >
            <span className="tab-icon">📝</span>
            Terms & Conditions
          </span>
        </div>

        {activeTab === "privacy-policy" && (
          <div className="content-section">
            <div className="rte-section">
              <RichTextEditor
                onChange={setPrivacyPolicy}
                value={privacyPolicy}
              />
            </div>
            <div className="form-footer">
              <button className="btn btn-main" onClick={handlePrivacySubmit}>
                Save Privacy Policy
              </button>
            </div>
          </div>
        )}

        {activeTab === "terms" && (
          <div className="content-section">
            <div className="rte-section">
              <RichTextEditor onChange={setTerms} value={terms} />
            </div>
            <div className="form-footer">
              <button className="btn btn-main" onClick={handleTermsSubmit}>
                Save Terms & Conditions
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
