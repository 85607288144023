import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center p-8  rounded-lg shadow-md max-w-md">
        <h1 className="text-4xl font-bold text-red-600 mb-4">Access Denied</h1>
        <div className="text-gray-600 mb-6">
          Sorry, you don't have permission to access this page.
        </div>
        <button
          onClick={() => navigate(-1)}
          className="bg-gray-500 hover:bg-gray-600 px-6 py-2 rounded transition-colors mt-5 mx-auto block"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
