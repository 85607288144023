import React, { useState } from "react";
import "./styles.css";
import DeleteIcon from "../../../assets/icons/delete.svg";

const RecruiterTestCases = ({ testCases, setTestCases }) => {
  const handleTestCaseChange = (index, field, value) => {
    const updatedTestCases = testCases.map((testCase, i) => {
      if (i === index) {
        return {
          ...testCase,
          [field]: field === "points" ? Number(value) : value,
        };
      }
      return testCase;
    });
    setTestCases(updatedTestCases);
  };

  const addTestCase = () => {
    setTestCases([
      ...testCases,
      { example: false, input: "", output: "", points: 10 },
    ]);
  };

  const addNewTestCase = () => {
    const newId = testCases.length + 1;
    setTestCases([
      ...testCases,
      { id: newId, input: "", output: "", points: 10 },
    ]);
  };

  const handleDelete = (id) => {
    if (testCases.length > 1) {
      setTestCases(testCases.filter((testCase) => testCase.id !== id));
    }
  };

  const handlePointsChange = (index, newPoints) => {
    const updatedTestCases = [...testCases];
    updatedTestCases[index] = {
      ...updatedTestCases[index],
      points: newPoints,
    };
    setTestCases(updatedTestCases);
  };

  return (
    <>
      <p className="test-case-header">Enter testcases</p>
      {testCases.map((testCase, index) => (
        <div key={testCase.id} style={{ display: "flex" }}>
          <div
            className="test-case-card"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-betweeen",
              }}
            >
              <div
                className="input-section"
                style={{
                  width: "50%",
                  padding: 10,
                }}
              >
                <span className="testcase-label">Input</span>
                <textarea
                  className="test-case-textarea"
                  value={testCase.input}
                  onChange={(e) =>
                    handleTestCaseChange(index, "input", e.target.value)
                  }
                  placeholder="Enter input"
                />
              </div>

              <div
                className="output-section"
                style={{
                  width: "50%",
                  padding: 10,
                }}
              >
                <span className="testcase-label">Output</span>
                <textarea
                  className="test-case-textarea"
                  value={testCase.output}
                  onChange={(e) =>
                    handleTestCaseChange(index, "output", e.target.value)
                  }
                  placeholder="Enter expected output"
                />
              </div>
            </div>
            <div className="points-container">
              <label htmlFor={`points-${index}`}>Points:</label>
              <input
                type="number"
                id={`points-${index}`}
                min="0"
                value={testCase.points || 0}
                onChange={(e) =>
                  handlePointsChange(index, Number(e.target.value))
                }
                className="question-title"
                style={{
                  border: "1px solid #333333",
                  borderRadius: 8,
                }}
              />
            </div>
          </div>

          <div className="controls" style={{ marginRight: -55 }}>
            <img
              src={DeleteIcon}
              height={16}
              width={16}
              alt="delete"
              onClick={() => handleDelete(testCase.id)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      ))}

      <button type="button" className="add-option-btn" onClick={addNewTestCase}>
        +Add more testcases
      </button>
    </>
  );
};

export default RecruiterTestCases;
