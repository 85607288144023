import React, { useState, useRef, useEffect } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import "draft-js/dist/Draft.css";
import "./styles.css";
import Toolbar from "./Toolbar";

const { hasCommandModifier } = KeyBindingUtil;

export default function RichTextEditor({ value, onChange }) {
  const [editorState, setEditorState] = useState(() => {
    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        const contentState = convertFromRaw(parsedValue);
        return EditorState.createWithContent(contentState);
      } catch (error) {
        const contentState = ContentState.createFromText(value);
        return EditorState.createWithContent(contentState);
      }
    }
    return EditorState.createEmpty();
  });
  const editor = useRef(null);

  // useEffect(() => {
  //   if (editor.current) {
  //     editor.current.focus();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (value) {
  //     try {
  //       const parsedValue = JSON.parse(value);
  //       const contentState = convertFromRaw(parsedValue);
  //       const newEditorState = EditorState.createWithContent(contentState);
  //       setEditorState(newEditorState);
  //     } catch (error) {
  //       const contentState = ContentState.createFromText(value);
  //       const newEditorState = EditorState.createWithContent(contentState);
  //       setEditorState(newEditorState);
  //     }
  //   }
  // }, [value]);

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        setEditorState(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const alignText = (alignment) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    const block = contentState.getBlockForKey(blockKey);

    const newContentState = Modifier.mergeBlockData(contentState, selection, {
      textAlignment: alignment,
    });

    setEditorState(
      EditorState.push(editorState, newContentState, "change-block-data")
    );
  };

  const undo = () => {
    setEditorState(EditorState.undo(editorState));
  };

  const blockStyleFn = (contentBlock) => {
    const textAlignment = contentBlock.getData().get("textAlignment");
    if (textAlignment) {
      return `text-align-${textAlignment}`;
    }
    return "";
  };

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    onChange && onChange(JSON.stringify(rawContent));
  };

  return (
    <>
      <div className="rich-text-editor">
        <div
          className="editor-container"
          onClick={() => editor.current?.focus()}
        >
          <Editor
            ref={editor}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={handleEditorStateChange}
            placeholder="Enter some text..."
            spellCheck={true}
            blockStyleFn={blockStyleFn}
            value={value}
          />
        </div>
      </div>
      <Toolbar
        editorState={editorState}
        onToggle={toggleInlineStyle}
        onToggleBlock={toggleBlockType}
        onAlignText={alignText}
        onUndo={undo}
      />
    </>
  );
}
