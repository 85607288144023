import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "../../../axiosInstance";
import { DEFAULT_JOB_DATA } from "../../../basic";
import DropdownFilter from "../../common/DropdownFilter";
import RichTextEditor from "../../RTE/RichTextEditor";
import "./createQuestion.scss";
import TestCase from "../../CodeEditor/TestCase";
import RecruiterTestCases from "../TestCases/TestCase";
import deleteIcon from "../../../assets/icons/delete.svg";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

const questionTypeValues = ["CODING", "MCQ"];

export default function CreateQuestions() {
  const { id: questionId } = useParams();
  const [questionType, setSelectedQuestionType] = useState("CODING");
  const [description, setDescription] = useState("");
  const [testCases, setTestCases] = useState([
    { example: true, input: "", output: "", points: 10 },
  ]);
  const [questionPoints, setQuestionPoints] = useState(30);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [testCasesError, setTestCasesError] = useState(null);
  const [loadingTestCases, setLoadingTestCases] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [mcqOptions, setMcqOptions] = useState([
    { id: 1, text: "", isCorrect: false },
    { id: 2, text: "", isCorrect: false },
    { id: 3, text: "", isCorrect: false },
    { id: 4, text: "", isCorrect: false },
  ]);

  useEffect(() => {
    const fetchQuestionDetails = async () => {
      if (!questionId) return;

      setIsLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("auth"));
        const response = await axios.get(
          `/api/v4/questions/${questionId}?token=${token}&isRecruiter=true`
        );

        if (response.data) {
          const question = response.data.data;

          setSelectedQuestionType(question.questionType || "CODING");
          setDescription(question.description || "");

          setValue("questionTitle", question.title);
          setValue("description", question.description);
          setValue('points',question.points)

          if (question.questionType === "MCQ" && question.metadata) {
            const options = question.metadata.options || [];
            const correctAnswers =
              question.metadata.correct_answers_index || [];

            const formattedOptions = options.map((text, index) => ({
              id: index + 1,
              text,
              isCorrect: correctAnswers.includes(index),
            }));

            setMcqOptions(formattedOptions);
          }
        }
      } catch (error) {
        console.error("Error fetching question details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuestionDetails();
  }, [questionId, setValue]);

  useEffect(() => {
    const fetchTestCases = async () => {
      if (!questionId || questionType !== "CODING") return;

      setLoadingTestCases(true);
      setTestCasesError(null);

      try {
        const token = JSON.parse(localStorage.getItem("auth"));
        const response = await axios.get(
          `/api/v4/questions/${questionId}/testcases?token=${token}`
        );

        if (response.data && response.data.data) {
          setTestCases(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching testcases:", error);
        setTestCasesError("Failed to load test cases. Please try again.");
      } finally {
        setLoadingTestCases(false);
      }
    };

    fetchTestCases();
  }, [questionId, questionType]);

  const onSubmit = async (formData) => {
    try {
      const role = localStorage.getItem("role");
      const user = JSON.parse(localStorage.getItem("user"));

      // Parse the description JSON and extract plain text
      let descriptionText = "";
      try {
        const descriptionJson = JSON.parse(description);
        descriptionText = descriptionJson.blocks
          .map(block => block.text)
          .join('\n');
      } catch (e) {
        // If parsing fails, use the description as is
        descriptionText = description;
      }

      let questionPayload;

      if (questionType === "MCQ") {
        const options = mcqOptions.map((opt) => opt.text);
        const correctAnswersIndex = mcqOptions
          .map((opt, index) => (opt.isCorrect ? index : -1))
          .filter((index) => index !== -1);

        questionPayload = {
          metadata: {
            options: options,
            category: "MULTI_SELECT",
            correct_answers_index: correctAnswersIndex,
          },
          title: formData.questionTitle,
          description: descriptionText,
          points: formData.points,
          questionType: questionType,
          creatorType: role.toUpperCase(),
          creatorId: user.recruiterId ?? user.email,
        };
      } else {
        const totalPoints = testCases.reduce(
          (sum, testCase) => sum + (testCase.points || 0),
          0
        );
        questionPayload = {
          metadata: {
            tags: "General",
          },
          title: formData.questionTitle,
          description: descriptionText,
          points: totalPoints,
          questionType: questionType,
          creatorType: role.toUpperCase(),
          creatorId: user.recruiterId ?? "",
        };
      }

      if (questionId) {
        // Update question details
        await axios.put(
          `/api/v4/questions/${questionId}?token=${user._token}`,
          questionPayload
        );

        // If it's a coding question, update test cases one by one
        if (questionType === "CODING") {
          // Update each test case individually
          for (const testCase of testCases) {
            const testCasePayload = {
              output: testCase.output,
              input: testCase.input,
              questionId: questionId,
              id: testCase.id,
              points: testCase.points,
            };

            await axios.put(
              `/api/v3/test-cases/${testCase.id}?token=${user._token}`,
              testCasePayload
            );
            toast.success("Question updated successfully");
          }
        }
      } else {
        // For new questions, send everything in one request
        const payload = {
          question: questionPayload,
          testcases: questionType === "CODING" ? testCases : undefined,
        };
        await axios.post(`/api/v4/questions?token=${user._token}`, payload);
        toast.success("Question created successfully");
      }

      navigate("/question-bank");
    } catch (error) {
      toast.error("Error saving question");

      console.error("Error saving question:", error);
    }
  };

  const handleSelectJob = (value) => {
    setSelectedQuestionType(value);
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
  };

  const handleOptionChange = (id, value) => {
    setMcqOptions((options) =>
      options.map((option) =>
        option.id === id ? { ...option, text: value } : option
      )
    );
  };

  const handleCorrectAnswer = (id) => {
    setMcqOptions((options) =>
      options.map((option) =>
        option.id === id ? { ...option, isCorrect: !option.isCorrect } : option
      )
    );
  };

  const addNewOption = () => {
    const newId = mcqOptions.length + 1;
    setMcqOptions([...mcqOptions, { id: newId, text: "", isCorrect: false }]);
  };

  const removeOption = (id) => {
    setMcqOptions((options) => options.filter((option) => option.id !== id));
  };

  const submitButtonText = questionId ? "Update Question" : "Create Question";

  const renderTestCases = () => {
    if (loadingTestCases) {
      return <Loader />;
    }

    if (testCasesError) {
      return (
        <div className="error-message">
          {testCasesError}
          <button
            onClick={() => {
              setTestCasesError(null);
              setLoadingTestCases(true);
            }}
            className="retry-button"
          >
            Retry
          </button>
        </div>
      );
    }

    return (
      <RecruiterTestCases testCases={testCases} setTestCases={setTestCases} />
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="main-container">
      <div className="question-section">
        <div className="flex justify-between mb-4 filter-container">
          <div className="filter-section">
            <div>
              <div className="relative">
                <span
                  style={{ fontSize: 20, fontWeight: 600, color: "#E2F0E7" }}
                >
                  {questionId ? "Update Question" : "Create question"}
                </span>
                {!questionId && (
                  <DropdownFilter
                    name="questionType"
                    styleClass="question-type"
                    dropdownName={questionType || "Select Category"}
                    dropdownOptions={questionTypeValues}
                    dropdownHandleClick={handleSelectJob}
                  />
                )}
              </div>
            </div>
          </div>

          <Form className="rich-text-editor" onSubmit={handleSubmit(onSubmit)}>
            {questionType === "CODING" ? (
              <>
                <div className="rte-main-container">
                  <div className="mb-4">
                    <span className="tw-mb-2 title">Question Title</span>
                    <Form.Control
                      type="text"
                      {...register("questionTitle", { required: true })}
                      placeholder="Enter question title"
                    />
                    {errors.questionTitle && (
                      <span className="error">This field is required</span>
                    )}
                  </div>

                  <span className="title">Description</span>
                  <div className="rte-section">
                    <RichTextEditor
                      onChange={handleDescriptionChange}
                      value={description}
                    />
                  </div>
                </div>

                <div className="test-cases-container">{renderTestCases()}</div>
              </>
            ) : (
              <div className="mcq-container">
                <div className="rte-main-container">
                  <div className="mb-4">
                    <span className="question-title">Question Title</span>
                    <Form.Control
                      type="text"
                      {...register("questionTitle", { required: true })}
                      placeholder="Enter question title"
                    />
                    {errors.questionTitle && (
                      <span className="error">This field is required</span>
                    )}
                  </div>

                  <div className="mb-4">
                    <span className="tw-mb-2 title">Question Points</span>
                    <Form.Control
                      type="number"
                      min="0"
                     
                      placeholder="Enter question points"
                      {...register("points", { required: true })}

                    />
                  </div>

                  <span className="question-title">Description</span>
                  <div className="rte-section">
                    <RichTextEditor
                      onChange={handleDescriptionChange}
                      value={description}
                    />
                  </div>
                </div>

                <div className="mcq-options-container">
                  <div className="title-row">
                    <span className="mcq-question-title">
                      Select correct answer
                    </span>
                    <span className="subtitle">(Can be more than 1)</span>
                  </div>

                  {mcqOptions.map((option) => (
                    <div key={option.id} className="option-row">
                      <input
                        type="checkbox"
                        checked={option.isCorrect}
                        onChange={() => handleCorrectAnswer(option.id)}
                        className="option-checkbox"
                      />
                      <input
                        type="text"
                        value={option.text}
                        onChange={(e) =>
                          handleOptionChange(option.id, e.target.value)
                        }
                        placeholder={`Option ${option.id}`}
                        className="option-input"
                      />
                      <button
                        className="option-delete"
                        onClick={() => removeOption(option.id)}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </button>
                    </div>
                  ))}

                  <button
                    type="button"
                    className="add-option-btn"
                    onClick={addNewOption}
                  >
                    +Add more answer
                  </button>
                </div>
              </div>
            )}

            <div className="questions-form-footer">
              <div className="questions-footer">
                <button type="submit" className="btn btn-main save-button">
                  {submitButtonText}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
