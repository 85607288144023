import {
  fetchQuestionsFailure,
  fetchQuestionsStart,
  fetchQuestionsSuccess,
  setRecruiterData,
} from "../slices/questions/recruiterQuestionSlice";

export const fetchQuestions =
  (currentPage, questionsPerPage, recruiterId, questionType) =>
  async (dispatch) => {
    try {
      const token = JSON.parse(localStorage.getItem("auth"));
      dispatch(fetchQuestionsStart());

      const response = await fetch(
        `https://api.assessmentpad.com/api/v4/questions?token=${token}&page=${currentPage}&count=${questionsPerPage}&recruiterId=${recruiterId}`,
        {
          params: {
            page: currentPage,
            count: questionsPerPage,
            recruiterId: recruiterId,
            type: questionType !== "ALL" ? questionType : undefined,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch questions");
      }

      const data = await response.json();
      dispatch(
        fetchQuestionsSuccess({
          questionData: data.data.data,
          totalPages: data.data.totalPages,
        })
      );
    } catch (error) {
      dispatch(fetchQuestionsFailure(error.message));
    }
  };

export const fetchRecruiterData = (email) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("auth"));
  try {
    const response = await fetch(
      `https://api.assessmentpad.com/api/v3/recruiters/search?token=${token}&email=${encodeURIComponent(
        email
      )}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    
    if (data?.data?.success) {
      dispatch(setRecruiterData({ id: data.data.id, email: email }));
    }
    return data;
  } catch (error) {
    console.error("Error fetching recruiter data:", error);
    throw error;
  }
};
