import React, { useState, useEffect } from "react";
import "./TermsAndConditions.css";
import AssessmentPadLogo from "./AssessmentPadLogo";
import Logo from "../assets/icons/logo.svg";

const TermsAndConditions = () => {
  const [termsData, setTermsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await fetch(
          `https://api.assessmentpad.com/api/app-configs?configName=TERMS_AND_CONDITIONS`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch terms and conditions");
        }

        const data = await response.json();
        setTermsData(data.data[0].data.description);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTerms();
  }, []);

  return (
    <div className="landing-page">
      <main className="terms-content">
        {loading && <div className="loading">Loading...</div>}
        {error && <div className="error">Error: {error}</div>}
        {termsData && (
          <div
            className="terms-content"
            dangerouslySetInnerHTML={{ __html: termsData }}
          />
        )}
      </main>
    </div>
  );
};

export default TermsAndConditions;
