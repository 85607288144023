import axios from 'axios';
import { 
  setLanguages, 
  setLanguagesLoading, 
  setLanguagesError 
} from '../slices/languages/languageSlice';

export const fetchLanguages = () => async (dispatch) => {
  try {
    dispatch(setLanguagesLoading(true));
    const token = localStorage.getItem('auth')
    
    const response = await axios.get(`https://api.assessmentpad.com/api/v3/code-languages?token=${token}`);
    
    const formattedLanguages = response.data.map(lang => ({
      id: lang.id,
      name: lang.name,
      template:lang.template
    }));
    
    formattedLanguages.sort((a, b) => {
      if (a.name.toLowerCase() === 'java') return -1;
      if (b.name.toLowerCase() === 'java') return 1;
      return a.name.localeCompare(b.name);
    });
    
    dispatch(setLanguages(formattedLanguages));
    dispatch(setLanguagesLoading(false));
    
  } catch (error) {
    console.error('Error fetching languages:', error);
    dispatch(setLanguagesError(error.message || 'Failed to fetch languages'));
    dispatch(setLanguagesLoading(false));
  }
}; 