import { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Step4 from "../components/recruiter/job-form-new/Step4";
import { useFormData } from "../context/formdatacontext";
import FormFooter from "./recruiter/job-form-new/FormFooter";
import JobPreview from "./recruiter/JobPreview";
import Rinput from "./Rinput";
import { setJobId } from "../redux/slices/questions/recruiterQuestionSlice";

export default function Jobdetails({
  formData,
  setFormData,
  publishJob,
  showJobPreview,
  setShowJobPreview,
}) {
  const { questions, selectedQuestions, loading, error, recruiterId } =
    useSelector((state) => state.recruiterQuestions);

  const selector = useSelector((state) => state.recruiterQuestions);
  // const [showJobPreview, setShowJobPreview] = useState(false);

  const closePopup = () => {
    setShowJobPreview(false);
  };

  const dispatch = useDispatch();
  // const handleCheckboxChange = (field, value) => (event) => {
  //   setAllFormData((prevState) => {
  //     if (prevState[field].includes(value)) {
  //       return {
  //         ...prevState,
  //         [field]: prevState[field].filter((item) => item !== value),
  //       };
  //     } else {
  //       return {
  //         ...prevState,
  //         [field]: [...prevState[field], value],
  //       };
  //     }
  //   });
  // };

  // const { allFormData, setAllFormData } = useFormData();
  // const formData = {
  //   ...{
  //     jobTitle: "",
  //     experienceRequired: "",
  //     jobType: "",
  //     from: "",
  //     to: "",
  //     contractType: "",
  //     additionalCompensations: "",
  //   },
  //   ...allFormData.jobDetails,
  // };

  // const [formData, setFormData] = useState({
  //   jobTitle: "",
  //   jobDescription: "",
  //   recruiterId: selector.recruiterId,
  // });

  const handleInputChange = (field) => (value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <div>
      <Modal
        style={{ zIndex: "100 !important" }}
        isOpen={showJobPreview}
        onRequestClose={closePopup}
        ariaHideApp={false}
        className={`jobify-modal-content`}
        overlayClassName={`jobify-modal-overlay theme-dark`}
        shouldCloseOnOverlayClick={true}
      >
        <JobPreview
          jobData={formData}
          isPublish={true}
          handleAction2={closePopup}
          handleAction1={publishJob}
        />
      </Modal>
      <div>
        <div className="row">
          <div className="col-12">
            <label className="test-title-text">Job title</label>
            <input
              name="jobTitle"
              style={{ marginTop: 10 }}
              className="recruiter-email"
              // text="Job Title"
              placeholdertext="Looking for a creative designer"
              onChange={() => handleInputChange("jobTitle")}
              value={formData.jobTitle}
            />
          </div>

          {/* <div className="col-6">
            <Rinput
              text="Experience Required?"
              placeholdertext="N/A"
              onChange={handleInputChange("experienceRequired")}
              value={formData.experienceRequired}
            />
          </div> */}
        </div>
        <div className="row">
          <div style={{ padding: 20 }}>
            <Step4 formData={formData} onDataChange={setFormData} />{" "}
          </div>
          {/* <div className="col-6 mt-5">
            <Rinput
              text="Job Type?"
              placeholdertext="Full-Time/Part Time"
              onChange={handleInputChange("jobType")}
              value={formData.jobType}
            />
          </div> */}
          {/* <div className="col-6 mt-5">
            <div>What type of job is it?</div>
            {[
              "Full-Time",
              "Part-Time",
              "Temporary",
              "Contract",
              "Internship",
              "Commision",
            ].map((type, index) => (
              <div className="form-check" key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`flexCheckDefaultJobType${index}`}
                  checked={formData.jobTypes.includes(type)}
                  onChange={() => handleCheckboxChange("jobTypes", type)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`flexCheckDefaultJobType${index}`}
                >
                  {type}
                </label>
              </div>
            ))}
          </div> */}
          {/* <div className="col-6 mt-5">
            <div className="row">
              <div className="col-6">
                <Rinput
                  text="From"
                  placeholdertext="$16"
                  value={formData.from}
                  onChange={handleInputChange("from")}
                />
              </div>
              <div className="col-6">
                <Rinput
                  text="To"
                  placeholdertext="$20"
                  value={formData.to}
                  onChange={handleInputChange("to")}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-6">
              Are there Any Additional Form of Compensation Offered?
              {[
                "Tips",
                "Commission",
                "Bonuses",
                "Store-Discount",
                "Other-Forms",
              ].map((comp, index) => (
                <div className="form-check" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`flexCheckDefaultComp${index}`}
                    // checked={formData.additionalCompensations.includes(comp)}
                    onChange={() =>
                      handleCheckboxChange("additionalCompensations", comp)
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`flexCheckDefaultComp${index}`}
                  >
                    {comp}
                  </label>
                </div>
              ))}
            </div>
          </div> */}
        </div>
        {/* <div className="row">
          <div className="col-12 mt-5">
            <Rinput
              text="Contract Type"
              placeholdertext="Per Hour"
              value={formData.contractType}
              onChange={handleInputChange("contractType")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-5">
            <Rinput
              text="Additional Compensation"
              placeholdertext="Tips/Bonuses"
              value={formData.additionalCompensations}
              onChange={handleInputChange("additionalCompensations")}
            />
          </div>
        </div> */}
      </div>
      {/* <FormFooter
        formData={formData}
        showPrev={false}
        isFinal={true}
        previewJob={() => setShowJobPreview(true)}
        publishJob={publishJob}
      /> */}
    </div>
  );
}
