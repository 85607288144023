import React from 'react';
import './HowItWorks.css';

const HowItWorks = () => {
  return (
    <section className="how-it-works">
      <div id="how-it-works" className="how-it-works-container">
        <div className="header">
          <span className="intro-icon">★</span>
            <span className="intro-text">How it works</span>
          <h2 className="title">We cooperate a lot with the companies that are hiring</h2>
        </div>
        <div className="cards">
          <div className="card">
            <h3>Process Initiation</h3>
            <p>Choose a plan and share your design project details with us: we're here to listen.</p>
          </div>
          <div className="card">

            <h3>Test Submissions</h3>
            <p>Sit back and relax: our expert designers will turn your vision into reality.</p>
          </div>
          <div className="card">

            <h3>Get ongoing support</h3>
            <p>Your subscription ensures you have continuous access to our design team.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;

