import React, { useRef, useState, useEffect } from "react";
import RecordContext from "./recordContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import axiosInstance from "../axiosInstance";
import { useAppSelector } from "../redux/hooks";
import { toast } from "react-toastify";

const RecordProvider = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // const { user } = useAppSelector((state) => state.auth);

  const handleNavigation = () => {
    navigate("/submissions");
  };

  const mainVideoRef = useRef(null);
  const camVideoRef = useRef(null);
  const mediaRecorderRef = useRef(null); // This ref will store the mediaRecorder instance

  let recordedChunks = [];
  const [isRecording, setIsRecording] = useState(false);
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const [streams, setStreams] = useState({});

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [pendingUploads, setPendingUploads] = useState([]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      uploadPendingVideos();
    };
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const captureScreen = async () => {
    let captureStream = null;
    try {
      captureStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          displaySurface: "monitor",
          mediaSource: "screen",
        },
      });

      const settings = captureStream.getVideoTracks()[0].getSettings();

      captureStream.getVideoTracks()[0].onended = () => {
        console.log(">>>>> SCREEN CAPTURE ENDED >>>>>>>>");
        captureStream.getTracks().forEach((track) => track.stop());
        stopAllStreams();
        setStreams({});
        setIsVideoRecording(false);
        if (
          mediaRecorderRef.current &&
          mediaRecorderRef.current.state === "recording"
        ) {
          stopRecording();
        }
      };

      if (settings.displaySurface === "monitor") {
        setStreams((prev) => ({ ...prev, screen: captureStream }));
        mainVideoRef.current.srcObject = captureStream;
        console.log(">>>>> SCREEN CAPTURED SUCCESSFULLY >>>>>>>>");
        return "SUCCESS";
      } else {
        return "SHARE_ENTIRE_SCREEN";
      }
    } catch (error) {
      console.log(">>>>>> SCREEN CAPTURED FAILED >>>>>>>", error);
      if (error === "SHARE_ENTIRE_SCREEN") {
        toast.error("Kindly share entire screen");
      } else {
        toast.error("Failed to share screen");
      }
      return "FAIL";
    }
  };

  const captureUserMedia = async () => {
    try {
      const userStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setStreams((prev) => ({ ...prev, camera: userStream }));
      userStream.getVideoTracks()[0].onended = () => {
        console.log(">>>>> USER MEDIA ENDED >>>>>>>>");
      };
      camVideoRef.current.srcObject = userStream;
      setIsVideoRecording(true);
      console.log(">>>>> USER MEDIA CAPTURED SUCCESSFULLY >>>>>>>>");
      return "SUCCESS";
    } catch (error) {
      console.log(">>>>>> USER MEDIA CAPTURED FAILED >>>>>>>");
      toast.error("Kindly enable camera and microphone settings");
      return "FAIL";
    }
  };

  const startMicrophone = async () => {
    try {
      const micStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setStreams((prev) => ({ ...prev, microphone: micStream }));
    } catch (error) {
      toast.error("Please provide microphone permission");
      return "fail";
    }
  };

  const storePendingUpload = async (videoChunk) => {
    try {
      // Store in IndexedDB
      const db = await openIndexedDB();
      const transaction = db.transaction(["pendingUploads"], "readwrite");
      const store = transaction.objectStore("pendingUploads");

      const upload = {
        id: Date.now(),
        chunk: videoChunk,
        timestamp: new Date(),
        email: JSON.parse(localStorage.getItem("email")),
        jobId: searchParams.get("jobId"),
      };

      await store.add(upload);
      setPendingUploads((prev) => [...prev, upload]);
    } catch (error) {
      console.error("Error storing video chunk:", error);
    }
  };

  const openIndexedDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("videoStorage", 1);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("pendingUploads")) {
          db.createObjectStore("pendingUploads", { keyPath: "id" });
        }
      };
    });
  };
  const uploadPendingVideos = async () => {
    if (!isOnline) return;

    try {
      const db = await openIndexedDB();
      const transaction = db.transaction(["pendingUploads"], "readwrite");
      const store = transaction.objectStore("pendingUploads");
      const pendingUploads = await store.getAll();

      for (const upload of pendingUploads) {
        try {
          await uploadChuksVideo(upload.chunk);
          // Remove from IndexedDB after successful upload
          await store.delete(upload.id);
        } catch (error) {
          console.error("Error uploading pending video:", error);
        }
      }

      setPendingUploads([]);
    } catch (error) {
      console.error("Error processing pending uploads:", error);
    }
  };

  const uploadChuksVideo = async (chunks) => {
    try {
      const email = JSON.parse(localStorage.getItem("email"));
      const token = localStorage.getItem("auth");
      const jobApplicationId = localStorage.getItem("jobApplicationId");

      const formData = new FormData();
      const blob = new Blob([chunks], { 
        type: mediaRecorderRef.current.mimeType || 'video/mp4' 
      });

      // Log file size in bytes and MB
      const fileSizeInBytes = blob.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      console.log("Video chunk size:", {
        bytes: fileSizeInBytes,
        megabytes: fileSizeInMB.toFixed(2) + " MB",
        format: mediaRecorderRef.current.mimeType
      });

      formData.append("emailId", email);
      formData.append("candidateJobId", jobApplicationId);
      formData.append("file", blob);
      formData.append("token", token);

      const response = await axiosInstance.post('/v1/video/publish', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("uploadChuksVideo response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error uploading video chunks:", error);
      // If upload fails due to connection issues, store for later
      if (!isOnline || error.message.includes("network")) {
        await storePendingUpload(chunks);
        toast.info("Video will be uploaded when internet connection is restored");
      }
      throw error;
    }
  };

  const startRecording = () => {
    try {
      const combinedTracks = Object.values(streams).flatMap((s) =>
        s.getTracks()
      );
      const combinedStream = new MediaStream(combinedTracks);

      // Updated options for MP4 recording
      const options = {
        mimeType: 'video/mp4; codecs="avc1.42E01E,mp4a.40.2"',
        videoBitsPerSecond: 2500000, // 2.5 Mbps
      };

      // Fallback if MP4 is not supported
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        options.mimeType = 'video/webm';
        console.log('MP4 not supported, falling back to WebM');
      }

      mediaRecorderRef.current = new MediaRecorder(combinedStream, options);
      console.log("MediaRecorder initialized", mediaRecorderRef.current.state);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          uploadChuksVideo(event.data);
        }
      };

      mediaRecorderRef.current.onstop = uploadVideo;
      mediaRecorderRef.current.start(20000);

      console.log("After start called:", mediaRecorderRef.current.state);
      setIsRecording(true);
      return "SUCCESS";
    } catch (error) {
      console.error("Recording error:", error);
      toast.error("Error in Recording");
      return "FAIL";
    }
  };

  const stopAllStreams = () => {
    Object.values(streams).forEach((stream) => {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    });

    if (mainVideoRef.current && mainVideoRef.current.srcObject) {
      mainVideoRef.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      mainVideoRef.current.srcObject = null;
    }

    if (camVideoRef.current && camVideoRef.current.srcObject) {
      camVideoRef.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      camVideoRef.current.srcObject = null;
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      stopAllStreams();
      setStreams({});
      handleNavigation();
    } else {
      console.error(
        "Recording has not started or mediaRecorder is not initialized."
      );
    }
  };

  const uploadVideo = async () => {
    console.log("upload");
    const blob = new Blob(recordedChunks, { type: "video/webm" });
    let email = JSON.parse(localStorage.getItem("email")); // This will remove quotes
    let jobId = localStorage.getItem("jobId");
    const authData = localStorage.getItem("auth");
    const token = authData; // This will remove quotes
    let details = `N/A:N/A:${email}:N/A:${jobId}:N/A:N/A:N/A:N/A:N/A`;
    let encoded = base64_encode(details);
    // const filename = `${jobId}:${email}.webm`;

    const formData = new FormData();
    // formData.append("jobId", localStorage.getItem("jobId"));
    // formData.append("email", email);
    formData.append("file", blob);

    try {
      const response = await fetch(
        // "https://jobify-upload.onrender.com/upload",
        `https://jobify-backend-wrapper-7323fa1818eb.herokuapp.com/v1/jobify/apis/candidates/jobs/apply?encodedDetails=${encoded}&token=${token}&isResumeUpload=false`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (data) {
        console.log(data.filePath);
        console.log(data);
        localStorage.removeItem("jobId");
        return "fail";
      }
    } catch (error) {
      console.error("Failed to upload video:", error);
    }
  };

  // Toggle PiP function
  const togglePiP = async () => {
    try {
      if (document.pictureInPictureEnabled) {
        if (document.pictureInPictureElement) {
          await document.exitPictureInPicture();
          return "SUCCESS";
        } else {
          if (camVideoRef.current) {
            await camVideoRef.current.requestPictureInPicture();
            return "SUCCESS";
          }
        }
      } else {
        new Error("Picture-in-Picture is not supported by this browser.");
        alert("Picture-in-Picture is not supported by this browser.");
      }
    } catch (error) {
      console.log(">>>>>> PIP FAILED >>>>>>>", error);
      return "FAIL";
    }
  };

  return (
    <RecordContext.Provider
      value={{
        mainVideoRef,
        camVideoRef,
        isRecording,
        isVideoRecording,
        captureScreen,
        captureUserMedia,
        startMicrophone,
        startRecording,
        stopRecording,
        togglePiP,
      }}
    >
      {props.children}
    </RecordContext.Provider>
  );
};
export default RecordProvider;
