import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questions: [],
  selectedQuestions: [],
  loading: false,
  error: null,
  recruiterId: null,
  recruiterEmail: null,
  jobId: "",
  totalPages: 0,
};

const recruiterQuestionSlice = createSlice({
  name: "recruiterQuestions",
  initialState,
  reducers: {
    fetchQuestionsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchQuestionsSuccess: (state, action) => {
      console.log({ action });
      state.loading = false;
      state.questions = action.payload.questionData;
      state.totalPages = action.payload.totalPages;
    },
    fetchQuestionsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setRecruiterData: (state, action) => {
      console.log({ action });
      state.recruiterId = action.payload.id;
      state.recruiterEmail = action.payload.email;
    },
    addSelectedQuestion: (state, action) => {
      const question = action.payload;
      if (!state.selectedQuestions.some((q) => q.id === question.id)) {
        state.selectedQuestions.push(question);
      }
    },
    removeSelectedQuestion: (state, action) => {
      if (action.payload === 'all') {
        state.selectedQuestions = [];
      } else {
        state.selectedQuestions = state.selectedQuestions.filter(
          (question) => question.id !== action.payload
        );
      }
    },
    setJobId: (state, action) => {
      console.log({ action });
      state.jobId = action.payload;
    },
  },
});

export const {
  fetchQuestionsStart,
  fetchQuestionsSuccess,
  fetchQuestionsFailure,
  setRecruiterData,
  addSelectedQuestion,
  removeSelectedQuestion,
  setJobId,
} = recruiterQuestionSlice.actions;

export default recruiterQuestionSlice.reducer;
