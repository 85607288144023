import React, { useState, useRef } from "react";
import { encode as base64_encode } from "base-64";
import "./testcase.css";

const TestCase = ({input, onInputChange, codeOutput, isRunning, activeTab, setActiveTab}) => {
  const [currentLine, setCurrentLine] = useState(1);
  const textareaRef = useRef(null);

  const getCursorLineNumber = (textarea) => {
    const cursorPosition = textarea.selectionStart;
    const textBeforeCursor = textarea.value.substring(0, cursorPosition);
    return (textBeforeCursor.match(/\n/g) || []).length + 1;
  };

  const handleTextareaSelect = (e) => {
    setCurrentLine(getCursorLineNumber(e.target));
  };

  const handleInputChange = (e) => {
    const newInput = e.target.value;
    onInputChange(newInput);
    setCurrentLine(getCursorLineNumber(e.target));
  };

  const renderWithLineNumbers = (text, showHighlight = false) => {
    const lines = text.split("\n");
    return (
      <div className="line-numbered-container">
        <div className="test-case-line-numbers">
          {lines.map((_, i) => (
            <div
              key={i + 1}
              className={`line-number ${
                showHighlight && currentLine === i + 1 ? "current-line" : ""
              }`}
            >
              {i + 1}
            </div>
          ))}
        </div>
        <pre className="code-content">{text}</pre>
      </div>
    );
  };

  return (
    <div className="tab-container">
      <div className="tab-color">
        <div
          onClick={() => setActiveTab("Input")}
          className={`tab-item ${
            activeTab === "Input" ? "active-tab" : "inactive-tab"
          }`}
        >
          Input
        </div>
        <span>|</span>
        <div
          onClick={() => setActiveTab("Output")}
          className={`tab-item ${
            activeTab === "Output" ? "active-tab" : "inactive-tab"
          }`}
        >
          Output {isRunning && <span className="running-indicator">Running...</span>}
        </div>
      </div>

      {activeTab === "Input" && (
        <div className="content-container">
          <div className="line-numbered-container">
            <div className="test-case-line-numbers">
              {input.split("\n").map((_, i) => (
                <div
                  key={i + 1}
                  className={`line-number ${
                    currentLine === i + 1 ? "current-line" : ""
                  }`}
                >
                  {i + 1}
                </div>
              ))}
              <div
                className={`line-number ${
                  currentLine === input.split("\n").length + 1
                    ? "current-line"
                    : ""
                }`}
              >
              </div>
            </div>
            <textarea
              ref={textareaRef}
              value={input}
              onChange={handleInputChange}
              onKeyUp={handleTextareaSelect}
              onClick={handleTextareaSelect}
              onSelect={handleTextareaSelect}
              className="test-case-input"
              placeholder="Enter your input here (e.g. 1 2 3)..."
            />
          </div>
        </div>
      )}

      {activeTab === "Output" && (
        <div className="content-container">
          <div className="text-gray-400">
            {renderWithLineNumbers(codeOutput, false)}
          </div>
        </div>
      )}
    </div>
  );
};

export default TestCase;
