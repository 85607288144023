import React from "react";

export default function Contactus() {
  return (
    <div
      className="contact-container"
      style={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "2rem",
        background: "linear-gradient(to bottom, #0d0d0d, #141616)",
        color: "#d6d6d6",
      }}
    >
      <div
        style={{
          maxWidth: "600px",
          width: "100%",
          textAlign: "center",
          background: "rgba(255, 255, 255, 0.05)",
          padding: "3rem",
          borderRadius: "10px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      >
        <h1
          style={{
            fontSize: "2.5rem",
            color: "#ffffff",
            marginBottom: "1.5rem",
            fontWeight: "600",
          }}
        >
          Contact Us
        </h1>
        <p
          style={{
            fontSize: "1.1rem",
            lineHeight: "1.6",
            color: "#d6d6d6",
            marginBottom: "1.5rem",
          }}
        >
          You can mail your queries at:{" "}
          <a
            href="mailto:hi@assessmentpad.com"
            style={{
              color: "#4dabf7",
              textDecoration: "none",
              fontWeight: "500",
              transition: "color 0.2s ease",
            }}
            onMouseOver={(e) => (e.target.style.color = "#74c0fc")}
            onMouseOut={(e) => (e.target.style.color = "#4dabf7")}
          >
            hi@assessmentpad.com
          </a>
        </p>
      </div>
    </div>
  );
}
