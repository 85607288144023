import React, { useState, useEffect } from "react";
import axios from "axios";

const PrivacyPolicy = () => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch existing privacy policy
    fetchPrivacyPolicy();
  }, []);

  const fetchPrivacyPolicy = async () => {
    try {
      const response = await axios.get("/api/settings/privacy");
      setContent(response.data.content);
    } catch (error) {
      console.error("Error fetching privacy policy:", error);
    }
  };

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await axios.post("/api/settings/privacy", { content });
      alert("Privacy Policy updated successfully!");
    } catch (error) {
      console.error("Error saving privacy policy:", error);
      alert("Error saving Privacy Policy");
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <h2 className="mb-4">Privacy Policys</h2>

      <button
        className="btn btn-primary mt-3"
        onClick={handleSave}
        disabled={loading}
      >
        {loading ? "Saving..." : "Save Changes"}
      </button>
    </div>
  );
};

export default PrivacyPolicy;
