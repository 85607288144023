import { FeatureCard } from './FeatureCard'
import { FeatureTag } from './FeatureTag'
import styles from './Features.module.css'

export function Features() {
  return (
    <div id="features" className={styles.container}>
      <div className={styles.content}>
        <div className={styles.cardGrid}>
          <FeatureCard
            title="Video and Microphone Integration"
            description="Seamlessly records the ongoing activity during the test taking procedure"
            icons={[]} 
          />
          <FeatureCard
            title="Feedback from job applicants:"
            description="Assessmentpad allows job applicants to share feedback once they finish the test"
            icons={[]}
            rating={5}
          />
        </div>
        <div>
          <div className={styles.tagContainer}>
            <FeatureTag text="Audio Enabled" />
            <FeatureTag text="Video Enabled" />
            <FeatureTag text="Cheating-free" />
            <FeatureTag text="Quality hiring" />
          </div>
          <div>
            <FeatureTag text="Unbiased hiring" />
            <FeatureTag text="Top Notch hiring process" />
          </div>
        </div>
      </div>
    </div>
  )
}

