import React from "react";
import { Outlet } from "react-router-dom";
import AppNav from "../AppNav";
// Import your Header/Navbar component here
// import Header from '../Header';

const MainLayout = () => {
  const isAuthenticated = localStorage.getItem("auth") !== null;

  return (
    <>
      {isAuthenticated && <AppNav />}
      <Outlet />
    </>
  );
};

export default MainLayout;
