import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  languages: [],
  loading: false,
  error: null
};

const languageSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    setLanguages: (state, action) => {
      state.languages = action.payload;
    },
    setLanguagesLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLanguagesError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setLanguages, setLanguagesLoading, setLanguagesError } = languageSlice.actions;

export const selectLanguages = (state) => state.languages.languages;

export default languageSlice.reducer; 