import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TermsAndConditions = () => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch existing terms and conditions
    fetchTerms();
  }, []);

  const fetchTerms = async () => {
    try {
      const response = await axios.get('/api/settings/terms');
      setContent(response.data.content);
    } catch (error) {
      console.error('Error fetching terms:', error);
    }
  };

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await axios.post('/api/settings/terms', { content });
      alert('Terms and Conditions updated successfully!');
    } catch (error) {
      console.error('Error saving terms:', error);
      alert('Error saving Terms and Conditions');
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <h2 className="mb-4">Terms and Conditions</h2>
      
      <button 
        className="btn btn-primary mt-3" 
        onClick={handleSave}
        disabled={loading}
      >
        {loading ? 'Saving...' : 'Save Changes'}
      </button>
    </div>
  );
};

export default TermsAndConditions; 