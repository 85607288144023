import { useMemo, useState, useEffect } from "react";
import "./index.css";
import QuestionsPanel from "./question-panel";
import DropdownFilter from "../../common/DropdownFilter";
import rightPaginationArrow from "../../../assets/images/rightPaginationArrow.svg";
import leftPaginationArrow from "../../../assets/images/leftPaginationArrow.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";
import Jobdetails from "../../Jobdetails";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecruiterData } from "../../../redux/actions/questionsActions";
import {
  removeSelectedQuestion,
  setJobId,
} from "../../../redux/slices/questions/recruiterQuestionSlice";
import Step4 from "../job-form-new/Step4";
import FormFooter from "../job-form-new/FormFooter";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import JobPreview from "../JobPreview";

export default function CreateJobNew() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    trigger,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      jobTitle: "",
      jobDescription: "",
      testTitle: "SDE 1 Screening",
      duration: "30",
      recruiterEmail: "",
      jobType: "REMOTE",
      contractType: "FULL_TIME"
    },
    mode: "onChange",
  });

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("job-details");
  const [isQuestionsPanelOpen, setIsQuestionsPanelOpen] = useState(false);
  const [showJobPreview, setShowJobPreview] = useState(false);

  const isAdminRole = localStorage.getItem("role") === "admin";

  const { questions, selectedQuestions, loading, error, recruiterId, jobId } =
    useSelector((state) => state.recruiterQuestions);

  const selector = useSelector((state) => state.recruiterQuestions);

  const [currentPage, setCurrentPage] = useState(1);
  const [buttonRange, setButtonRange] = useState({ start: 1, end: 10 });
  const totalPages = useMemo(
    () => Math.ceil(questions?.length / 13),
    [questions]
  );

  const [priorityOrder, setPriorityOrder] = useState([
    { type: "MCQ", sequenceId: 1 },
    { type: "CODING", sequenceId: 2 },
  ]);

  const formData = {
    jobTitle: watch("jobTitle"),
    jobDescription: watch("jobDescription"),
    recruiterEmail: watch("recruiterEmail"),
  };

  const handlePrevRange = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (buttonRange.start >= currentPage) {
      const prevStart = Math.min(buttonRange.start - 1, totalPages - 10);
      const prevEnd = Math.min(buttonRange.end - 1, totalPages);
      if (currentPage <= buttonRange.start) {
        setButtonRange({ start: prevStart, end: prevEnd });
      }
    }
  };

  const handleNextRange = () => {
    if (totalPages > currentPage) {
      setCurrentPage(currentPage + 1);
    }
    if (buttonRange.end <= currentPage) {
      const nextStart = Math.min(buttonRange.start + 1, totalPages - 10 + 1);
      const nextEnd = Math.min(buttonRange.end + 1, totalPages);
      if (currentPage < totalPages) {
        setButtonRange({ start: nextStart, end: nextEnd });
      }
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = buttonRange.start; i <= buttonRange.end; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? "active" : ""}
          // disabled={i > Math.ceil(filteredQuestions.length / 10)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  const handleEmailSubmit = async (e) => {
    if (e.key === "Enter") {
      try {
        const data = await dispatch(fetchRecruiterData(watch("recruiterEmail")));
        if (data?.success) {
          toast.success("Recruiter found");
        } else {
          toast.error("Recruiter not found");
        }
      } catch (error) {
        toast.error("Failed to find recruiter");
        console.error("Error finding recruiter:", error);
      }
    }
  };

  const handleDeleteQuestion = (questionId) => {
    dispatch(removeSelectedQuestion(questionId));
  };

  const publishJob = async (data) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const role = localStorage.getItem("role");
    try {
      const response = await fetch(
        `https://api.assessmentpad.com/api/v3/jobs?token=${user._token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            description: data.jobDescription,
            recruiterId:
              role === "admin" ? selector.recruiterId : user.recruiterId,
            title: data.jobTitle,
            jobType: data.jobType,
            contractType: data.contractType
          }),
        }
      );

      if (!response.ok) {
        toast.error("Failed to publish job");
        throw new Error("Failed to publish job");
      }

      const responseData = await response.json();
      dispatch(setJobId(responseData.id));

      toast.success("Job published successfully");
      setActiveTab("add-question");
      setShowJobPreview(false);
    } catch (error) {
      console.error("Error publishing job:", error);
      toast.error("Failed to publish job");
    }
  };

  const handlePriorityClick = (type) => {
    setPriorityOrder((prev) => {
      const otherType = type === "MCQ" ? "CODING" : "MCQ";
      return [
        { type, sequenceId: 1 },
        { type: otherType, sequenceId: 2 },
      ];
    });
  };

  const handleCreateTest = async (data) => {
    const token = JSON.parse(localStorage.getItem("auth"));
    try {
      if (!jobId) {
        toast.error("Please create a job first");
        return;
      }

      if (selectedQuestions.length === 0) {
        toast.error("Please select at least one question");
        return;
      }

      const payload = {
        jobId,
        title: data.testTitle,
        durationInMinutes: parseInt(data.duration),
        priorityOrder,
        questions: selectedQuestions.map((question, index) => ({
          sequenceNumber: index.toString(),
          questionId: question.id,
        })),
      };

      const response = await axios.post(
        `https://api.assessmentpad.com/api/v4/tests/?token=${token}`,
        payload
      );

      if (response.status === 201 || response.status === 200) {
        toast.success("Test created successfully!");
        reset({
          jobTitle: "",
          jobDescription: "",
          testTitle: "SDE 1 Screening",
          duration: "30",
          recruiterEmail: "",
        });
        dispatch(removeSelectedQuestion("all"));
        setActiveTab("job-details");
      } else {
        toast.error("Failed to create test");
      }
    } catch (error) {
      console.error("Error creating test:", error);
      toast.error(error.response?.data?.message || "Failed to create test");
    }
  };

  const handlePreviewClick = async () => {
    const isValid = await trigger();

    if (!isValid) {
      toast.error("Please fill all required fields");
      return;
    }

    setShowJobPreview(true);
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "jobTitle" && value.jobTitle) {
        clearErrors("jobTitle");
      }
      if (name === "recruiterEmail" && value.recruiterEmail) {
        clearErrors("recruiterEmail");
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, clearErrors]);

  return (
    <>
      <Modal
        style={{ zIndex: "100 !important" }}
        isOpen={showJobPreview}
        onRequestClose={() => setShowJobPreview(false)}
        ariaHideApp={false}
        className={`jobify-modal-content`}
        overlayClassName={`jobify-modal-overlay theme-dark`}
        shouldCloseOnOverlayClick={true}
      >
        <JobPreview
          jobData={formData}
          isPublish={true}
          handleAction2={() => setShowJobPreview(false)}
          handleAction1={async () => {
            const isValid = await trigger();
            if (isValid) {
              handleSubmit(publishJob)();
            } else {
              toast.error("Please fill all required fields");
            }
          }}
        />
      </Modal>
      <div className="admin-container">
        <form
          onSubmit={(e) => {
            if (activeTab === "add-question") {
              e.preventDefault();
              return;
            }
            handleSubmit(publishJob)(e);
          }}
        >
          {isAdminRole && (
            <div className="email-input-container">
              <input
                type="email"
                {...register("recruiterEmail", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                placeholder="Enter recruiter email and press Enter"
                onKeyPress={handleEmailSubmit}
                className="recruiter-email"
              />
              {errors.recruiterEmail && (
                <span className="error-message">
                  {errors.recruiterEmail.message}
                </span>
              )}
            </div>
          )}

          <div className="main-section">
            <div className="tabs-container">
              <span
                className={`admin-tab ${
                  activeTab === "job-details" ? "active" : ""
                }`}
                onClick={() => setActiveTab("job-details")}
              >
                <span className="tab-icon">📋</span>
                Job details
              </span>

              <span
                className={`admin-tab ${
                  activeTab === "add-question" ? "active" : ""
                }`}
                onClick={() => {
                  setActiveTab("add-question");
                }}
              >
                <span className="tab-icon">➕</span>
                Add question
              </span>
            </div>

            {activeTab === "job-details" && (
              <div>
                <div className="col-12" style={{ padding: 20 }}>
                  <label className="test-title-text">Job title</label>
                  <input
                    {...register("jobTitle", {
                      required: "Job title is required",
                      minLength: {
                        value: 3,
                        message: "Job title must be at least 3 characters",
                      },
                    })}
                    style={{ marginTop: 10 }}
                    className="recruiter-email"
                    placeholdertext="Looking for a creative designer"
                    name="jobTitle"
                  />
                  {errors.jobTitle && (
                    <span className="error-message">
                      {errors.jobTitle.message}
                    </span>
                  )}
                </div>

                <div className="col-12" style={{ padding: 20 }}>
                  <label className="test-title-text">Job Type</label>
                  <div style={{ marginTop: 10, display: 'flex', gap: '20px' }}>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <input
                        type="radio"
                        {...register("jobType")}
                        value="REMOTE"
                      />
                      Remote
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <input
                        type="radio"
                        {...register("jobType")}
                        value="HYBRID"
                      />
                      Hybrid
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <input
                        type="radio"
                        {...register("jobType")}
                        value="ONSITE"
                      />
                      Onsite
                    </label>
                  </div>
                </div>

                <div className="col-12" style={{ padding: 20 }}>
                  <label className="test-title-text">Contract Type</label>
                  <div style={{ marginTop: 10, display: 'flex', gap: '20px' }}>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <input
                        type="radio"
                        {...register("contractType")}
                        value="FULL_TIME"
                      />
                      Full Time
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <input
                        type="radio"
                        {...register("contractType")}
                        value="PART_TIME"
                      />
                      Part Time
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <input
                        type="radio"
                        {...register("contractType")}
                        value="FREELANCE"
                      />
                      Freelance
                    </label>
                  </div>
                </div>

                <div style={{ padding: 20 }}>
                  <Step4
                    value={watch("jobDescription")}
                    onChange={(value) => setValue("jobDescription", value)}
                  />
                </div>
              </div>
            )}

            {activeTab === "add-question" && (
              <>
                <div className="test-details">
                  <span className="test-details-text">Test details</span>
                  <div className="test-inputs">
                    <div className="input-group">
                      <label className="test-title-text">
                        Enter test title
                      </label>
                      <input
                        type="text"
                        {...register("testTitle", {
                          required: "Test title is required",
                        })}
                        className="test-title"
                      />
                      {errors.testTitle && (
                        <span className="error-message">
                          {errors.testTitle.message}
                        </span>
                      )}
                    </div>
                    <div className="input-group">
                      <label>Duration(in mins)</label>
                      <input
                        type="text"
                        {...register("duration", {
                          required: "Duration is required",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a valid number",
                          },
                        })}
                        className="test-duration"
                      />
                      {errors.duration && (
                        <span className="error-message">
                          {errors.duration.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="selection-questions-section">
                    {selectedQuestions.length > 0 && (
                      <div className="questions-list-home">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ color: "#E2F0E7", fontWeight: 600 }}>
                            Selected Questions
                          </span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "95px",
                            }}
                          >
                            <DropdownFilter
                              name="questionType"
                              styleClass="viewAllQnPageDropdown"
                              dropdownName={"Type"}
                              dropdownOptions={["ALL", "Coding", "MCQ"]}
                              // dropdownHandleClick={handleSelectJob}
                            />
                          </div>
                          <span>Remove all</span>
                        </div>
                        {selectedQuestions.map((question, index) => (
                          <div key={question.id} style={{ display: "flex" }}>
                            <div className="question-item">
                              <div className="question-info">
                                <div className="question-title">
                                  <span className="question-number column-id">
                                    {index + 1}.
                                  </span>
                                  <span className="column-title">
                                    {question.title}
                                  </span>
                                </div>

                                <span className="question-type column-type">
                                  {question.questionType}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginLeft: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDeleteQuestion(question.id)}
                            >
                              <img src={DeleteIcon} alt="Delete" />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() => {
                      setIsQuestionsPanelOpen(true);
                    }}
                    className="add-question-btn"
                  >
                    +Add question
                  </button>
                </div>
                {selectedQuestions.length > 0 && (
                  <div
                    className="pagination"
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="paginationNoBtns">
                        {renderPaginationButtons()}
                      </div>

                      <div>
                        <div className="paginationNextPrevBtn">
                          <button
                            className="leftPaginationArrow"
                            onClick={handlePrevRange}
                            disabled={currentPage === 1}
                          >
                            <img
                              className="rightPaginationArrow"
                              src={leftPaginationArrow}
                              alt="Next Question"
                            />
                          </button>
                          <button
                            className="rightPaginationArrow"
                            onClick={handleNextRange}
                            disabled={currentPage === totalPages}
                          >
                            <img
                              className="rightPaginationArrow"
                              src={rightPaginationArrow}
                              alt="Next Question"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <span style={{ color: "#E2F0E7", fontWeight: 600 }}>
                        Choose Priority
                      </span>

                      <div className="priority-buttons">
                        <button
                          className={`priority-btn ${
                            priorityOrder[0].type === "MCQ" ? "active" : ""
                          }`}
                          onClick={() => handlePriorityClick("MCQ")}
                        >
                          MCQ
                        </button>

                        <button
                          className={`priority-btn ${
                            priorityOrder[0].type === "CODING" ? "active" : ""
                          }`}
                          onClick={() => handlePriorityClick("CODING")}
                        >
                          Coding
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="form-footer">
            <div className="preview-footer-card">
              <div>
                <h6 className="footer-title">{watch("jobTitle")}</h6>
                <div className="posted-by">
                  Job posted by {watch("recruiterEmail")}(HR){" "}
                </div>
              </div>

              {activeTab === "job-details" ? (
                <button
                  type="button"
                  className="mx-2 btn btn-main"
                  onClick={handlePreviewClick}
                >
                  Preview and Publish Job
                </button>
              ) : (
                <button
                  type="button"
                  className="mx-2 btn btn-main"
                  onClick={handleSubmit(handleCreateTest)}
                >
                  Create Test
                </button>
              )}
            </div>
          </div>
        </form>

        {isQuestionsPanelOpen && (
          <QuestionsPanel
            isOpen={isQuestionsPanelOpen}
            onClose={() => setIsQuestionsPanelOpen(false)}
          />
        )}
      </div>
    </>
  );
}
